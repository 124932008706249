import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { BackButton } from 'components/Shared';
import { QnProgressBar } from 'pages/WordsOfTheDay/components';

import {
  Container,
  LogoContainer,
  Content,
  ProgressBarContainer,
} from './SpellTopBar.styles';

const SpellTopBar = ({ progress, count, totalQn, isTutorial }) => {
  const history = useHistory();
  const { subject } = useParams();

  return (
    <Container>
      <LogoContainer
        onClick={() => history.push('/dashboard?view=koochallenge')}
      />
      <Content>
        <BackButton
          btnBgColor="#7b7b7b"
          backText="Back"
          className="back-btn"
          onClick={() => {
            if (isTutorial) {
              return;
            } else {
              history.push(`/words-of-the-day/${subject}`);
            }
          }}
        />
        <ProgressBarContainer>
          <p>
            <span>{count}</span>/{totalQn}
          </p>
          <QnProgressBar progress={progress} totalQn={totalQn} />
        </ProgressBarContainer>
      </Content>
    </Container>
  );
};

export default SpellTopBar;
