import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getRewards } from 'store/dashboard/rewardsSlice';

import { BackButton } from 'components/Shared';
import CoinImg from './imgs/koko.png';

import { secondsToMinute } from '../../../helpers';

import {
  ItemHeader,
  LogoTitle,
  TopHeader,
  WrapImage,
  InfoKoko,
  LineDivider,
  Timer,
} from './CategoryHeader.style';

const CategoryHeader = ({ categoryTitle, subject, timeRemaining }) => {
  const { t } = useTranslation(['brainGames', 'common']);
  const { rewards, isLoading } = useSelector((state) => state.rewards);
  const { isLoading: isBrainGameLoading } = useSelector(
    (state) => state.brainGame
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch]);

  const history = useHistory();
  const goBack = useCallback(() => {
    history.push(`/brain-game/${subject}?skip=true&subject=${subject}`);
  }, [history, subject]);

  const LogoImage = require(`./imgs/category-${categoryTitle}.png`);
  return (
    <TopHeader>
      <ItemHeader>
        <BackButton
          onClick={goBack}
          btnBgColor="rgba(11, 11, 11, 0.24)"
          backText={t('common:back', 'Back')}
          styles={{ marginTop: '0.5rem', marginLeft: '2rem' }}
        />
      </ItemHeader>
      <WrapImage>
        <LogoTitle src={LogoImage} />
      </WrapImage>
      <ItemHeader>
        <InfoKoko>
          <Timer>
            <p className="header">
              {t('brainGames:welcome.time.remaining', 'Remaining Time')}
            </p>
            <p className="time">
              {isBrainGameLoading
                ? '25:00'
                : secondsToMinute(timeRemaining) ?? '25:00'}
            </p>
          </Timer>
          <LineDivider />
          <img src={CoinImg} alt="Koko Credit" />
          <span>{!isLoading && rewards && rewards.TotalKoko}</span>
        </InfoKoko>
      </ItemHeader>
    </TopHeader>
  );
};

export default React.memo(CategoryHeader);
