import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledBadge = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottomPosition};
  right: ${(props) => props.rightPosition ?? '0%'};
  padding: 12px 8px;
  border-radius: 1rem 0 1rem 0;
  z-index: 2;
  background-image: linear-gradient(135deg, #ff8000, #ff3d57);
  font-size: calc(0.01273251622 * 100vw);
  font-weight: 700;
  color: white;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    font-size: 18px;
    width: 188px;
    right: ${(props) => props.rightPosition ?? '3.5%'};
    padding: 16px 8px;
    bottom: 4.5%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    right: 2.5%;
  }

  @media (max-width: 650px) {
    right: 2.9%;
  }

  @media (max-width: 550px) {
    right: 3.4%;
  }

  @media (max-width: 480px) {
    right: 4%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    right: 4.65%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileS}px`}) {
    right: 4.75%;
  }
`;

const ClosedOnSundayBadge = ({ rightPosition, bottomPosition }) => {
  const { t } = useTranslation('dashboardCards');
  return (
    <StyledBadge rightPosition={rightPosition} bottomPosition={bottomPosition}>
      {t('dashboardCards:superHeroChallenge.closed', 'Closed on Sunday')}
    </StyledBadge>
  );
};

export default ClosedOnSundayBadge;
