/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
// assets
import LightIcon from 'assets/img/kooquiz/videoSolution/light.svg';
import CloseIcon from 'assets/img/kooquiz/videoSolution/close.svg';
import SearchIcon from 'assets/img/kooquiz/videoSolution/search.svg';

import { fetchWrapper } from 'services/login';
import { fetchQuestionVideoCode } from 'services/kooQuiz';

import { VideoPlayerModal } from 'components/Shared';

import {
  TriggerButton,
  Wrapper,
  Container,
  Heading,
  SearchBar,
  Delete,
  Input,
  SearchButton,
  ErrorMessage,
} from './KooQuizVideoSolution.styles';

function isNumeric(str) {
  if (typeof str !== 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

const KooQuizVideoSolution = () => {
  const { t } = useTranslation(['kooQuizVideoSolution']);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [videoCode, setVideoCode] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const modalRef = useRef(null);

  const onSearchVideo = async (e) => {
    e.preventDefault();
    if (inputValue === '' || !isNumeric(inputValue)) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
      setIsLoading(true);
      try {
        const res = await fetchWrapper(fetchQuestionVideoCode, inputValue);
        if (res === null) {
          setShowErrorMessage(true);
        } else {
          setVideoCode(res);
          setShowVideoModal(true);
        }
      } catch (err) {
        throw new Error(err?.message ?? 'Get question video code failed');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onClickModal = (e) => {
    if (e.target === modalRef.current) {
      setIsOpen(false);
    }
  };

  const onInputChange = (e) => {
    if (e.target.value === '') {
      setShowErrorMessage(false);
    }
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
      setShowErrorMessage(false);
    }
  }, [isOpen]);

  return (
    <>
      <VideoPlayerModal
        isOpen={showVideoModal}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
        }}
        videoId={videoCode}
        onClose={() => setShowVideoModal(false)}
      />
      <TriggerButton onClick={() => setIsOpen((prev) => !prev)}>
        <img src={LightIcon} title="solution" alt="" />
      </TriggerButton>
      <Wrapper isOpen={isOpen} onClick={onClickModal} ref={modalRef}>
        <Container>
          <Heading>
            {t(
              'kooQuizVideoSolution:heading1',
              'Need help answering a question?'
            )}
            <br />
            {t(
              'kooQuizVideoSolution:heading2',
              'Key in the Question ID to watch solution.'
            )}
          </Heading>
          <SearchBar onSubmit={onSearchVideo}>
            <Input
              value={inputValue}
              onChange={onInputChange}
              placeholder="e.g. 12345678"
            />
            <Delete onClick={() => setInputValue('')}>
              <img src={CloseIcon} alt="" />
            </Delete>
            <SearchButton type="submit">
              <img src={SearchIcon} alt="" />
            </SearchButton>
          </SearchBar>
          {!showErrorMessage && isLoading && (
            <Spinner animation="border" size="sm" />
          )}
          <ErrorMessage isShow={showErrorMessage}>
            {t(
              'kooQuizVideoSolution:error',
              'Video for this Question ID does not exist.'
            )}
          </ErrorMessage>
        </Container>
      </Wrapper>
    </>
  );
};

export default KooQuizVideoSolution;
