import moment from 'moment';
import { CHINESE_LANGUAGES } from 'constants/index';

const chineseHourWords = (hour) => {
  if (hour < 9) {
    return '早上';
  } else if (hour < 12) {
    return '上午';
  } else if (hour < 18) {
    return '下午';
  } else {
    return '晚上';
  }
};
const formatI18nDateTime = ({
  format,
  value,
  time,
  timeOnly,
  dateOnly,
  yearDate,
  language,
}) => {
  const isCurrentLanguageChinese = CHINESE_LANGUAGES[language];
  if (isCurrentLanguageChinese) {
    if (timeOnly || time) {
      const timeWords = chineseHourWords(moment(value).format('HH'));
      if (time) {
        return `YYYY 年 M 月 DD 日，${timeWords} h:mm `;
      }
      return `${timeWords} hh:mm`;
    } else if (dateOnly) {
      return 'M 月 DD 日';
    } else if (yearDate) {
      return 'YYYY 年 M 月';
    }
    return 'YYYY 年 M 月 DD 日';
  }
  return format;
};

export default formatI18nDateTime;
