import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 100%;
  }
  .modal-content {
    background-color: transparent;
    align-items: center;
    border: none;
  }
`;

export const StyledModalBody = styled.div`
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`;

export const StyledModalBodyInner = styled.div`
  padding: 10px 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1376px;
  height: 100%;

  @media (max-width: 740px) {
    padding: 0px;
    width: 100%;
  }
`;

export const StyledModalMovieWrap = styled.div`
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const StyledErrorContainer = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  text-align: center;

  a {
    color: #fff;
    text-decoration: none;
    background-color: #fe7121;
    padding: 10px 2rem;
    border-radius: 2rem;
  }

  @media (max-width: 625px) {
    bottom: 15%;
  }
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  z-index: 2;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  top: 0;
  right: -45px;
  border: none;
  background: transparent;
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
  :before,
  :after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px;
  }

  @media (max-width: 740px) {
    right: 0px;
    top: -35px;
  }
`;
