/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  postParentProfile,
  postParentPassword,
  postChildPassword,
  postChildDisplayName,
  postChildLoginId,
  fetchParentsChildren,
  fetchChildSubs,
} from 'services/parentSettings';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';

// Redux Thunks
export const getParentDetails = createAsyncThunk(
  'parentSettings/getParentDetails',
  async (parentID) => {
    const res = await fetchWrapper(fetchParentsChildren, parentID);
    return handleResponseV2(res);
  }
);

export const updateParentProfile = createAsyncThunk(
  'parentSettings/updateParentProfile',
  async (params) => {
    try {
      const res = await fetchWrapper(postParentProfile, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update parent profile failed');
    }
  }
);

export const changeParentPassword = createAsyncThunk(
  'parentSettings/changeParentPassword',
  async (params) => {
    try {
      const res = await fetchWrapper(postParentPassword, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update parent password failed');
    }
  }
);

export const changeChildPassword = createAsyncThunk(
  'parentSettings/changeChildPassword',
  async (params) => {
    try {
      const res = await fetchWrapper(postChildPassword, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update child password failed');
    }
  }
);

export const updateChildDisplayName = createAsyncThunk(
  'parentSettings/updateChildDisplayName',
  async (params) => {
    try {
      const res = await fetchWrapper(postChildDisplayName, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update child display name failed');
    }
  }
);

export const updateChildLoginId = createAsyncThunk(
  'parentSettings/updateChildLoginId',
  async (params) => {
    try {
      const res = await fetchWrapper(postChildLoginId, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update child login id failed');
    }
  }
);

export const getChildrenList = createAsyncThunk(
  'parentSettings/getChildrenList',
  async (id) => {
    const res = await fetchWrapper(fetchParentsChildren, id);
    const handledRes = handleResponseV2(res);
    return handledRes.students ?? [];
  }
);

export const getChildSubs = createAsyncThunk(
  'parentSettings/getChildSubs',
  async (id) => {
    const res = await fetchWrapper(fetchChildSubs, id);
    return handleResponseV2(res);
  }
);

const initialState = {
  isLoading: false,
  error: null,
  parentDetails: null,
  uuid: '',
  kidId: '',
  childrenProfile: null,
  parentProfileUpdate: null,
  updateParentPassword: null,
  updateChildPassword: null,
  childDisplayNameUpdate: null,
  childLoginIdUpdate: null,
  isUpdateLoading: false,
  isUpdateError: null,
  childrenList: {
    isLoading: false,
    data: [],
    isError: null,
  },
  childSubs: {
    isLoading: false,
    data: [],
    isError: null,
  },
};

const parentSettingsSlice = createSlice({
  name: 'parentSettings',
  initialState,
  reducers: {
    reset: (state) => {
      state.isUpdateError = null;
      state.parentProfileUpdate = null;
      state.childDisplayNameUpdate = null;
      state.childLoginIdUpdate = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getParentDetails.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          error: null,
          parentDetails: null,
          childrenProfile: null,
        };
      })
      .addCase(getParentDetails.fulfilled, (state, action) => {
        let rest = null;
        let students = null;
        if (action.payload) {
          Object.entries(action.payload).forEach(([key, value]) => {
            if (key === 'students') {
              students = value;
            } else {
              rest = {
                ...rest,
                [key]: value,
              };
            }
          });
        }
        return {
          ...state,
          isLoading: false,
          error: null,
          parentDetails: rest,
          childrenProfile: students,
        };
      })
      .addCase(getParentDetails.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.error.message,
          parentDetails: null,
          childrenProfile: null,
        };
      })
      .addCase(changeParentPassword.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          error: null,
          updateParentPassword: null,
        };
      })
      .addCase(changeParentPassword.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: null,
          updateParentPassword: action.payload,
        };
      })
      .addCase(changeParentPassword.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.error.message,
          updateParentPassword: null,
        };
      })
      .addCase(changeChildPassword.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          error: null,
          updateChildPassword: null,
        };
      })
      .addCase(changeChildPassword.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: null,
          updateChildPassword: action.payload,
        };
      })
      .addCase(changeChildPassword.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.error.message,
          updateChildPassword: null,
        };
      })

      .addCase(getChildrenList.pending, (state) => {
        state.childrenList.isLoading = true;
        state.childrenList.data = [];
        state.childrenList.isError = null;
      })
      .addCase(getChildrenList.fulfilled, (state, action) => {
        state.childrenList.isLoading = false;
        state.childrenList.data = action.payload;
        state.childrenList.isError = null;
      })
      .addCase(getChildrenList.rejected, (state, action) => {
        state.childrenList.isLoading = false;
        state.childrenList.data = [];
        state.childrenList.isError = action.error.message;
      })
      .addCase(getChildSubs.pending, (state) => {
        state.childSubs.isLoading = true;
        state.childSubs.data = [];
        state.childSubs.isError = null;
      })
      .addCase(getChildSubs.fulfilled, (state, action) => {
        state.childSubs.isLoading = false;
        state.childSubs.data = action.payload;
        state.childSubs.isError = null;
      })
      .addCase(getChildSubs.rejected, (state, action) => {
        state.childSubs.isLoading = false;
        state.childSubs.data = [];
        state.childSubs.isError = action.error.message;
      });
  },
});

const { actions, reducer } = parentSettingsSlice;
export const { reset } = actions;
export default reducer;
