import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const StyledImgContainer = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    svg {
      width: 60px;
      height: 60px;
    }
  }
`;
