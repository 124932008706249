import { isNil } from 'ramda';

export const emailMasking = (email) => {
  const mailDomain = email?.split('@').pop();
  const firstChar = email?.charAt(0);

  if (isNil(email)) return '-';

  return `${firstChar}*****@${mailDomain}`;
};
