import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty } from 'ramda';
import useSound from 'use-sound';
import { unwrapResult } from '@reduxjs/toolkit';
import { getUserInfos } from 'store/lookup/lookupSlice';

import { issuePeerChallenge } from 'store/multiplayer/multiplayerSlice';

import { fetchWrapper } from 'services/login';
import { getPCStatus } from 'services/multiplayerv2';

import useResponsiveValue from 'hooks/responsive/useResponsiveValue';
import useIsMobile from 'hooks/responsive/useIsMobile';
import useIsTablet from 'hooks/responsive/useIsTablet';
import useDashboard from 'hooks/useDashboard';
import useLocalStorage from 'hooks/useLocalStorage';
import usePeerChallengeAttempts from 'hooks/usePeerChallengeAttempts';

import { withSubscriptionCheck } from 'helpers/hoc';
import useClickOutside from 'helpers/useClickOutside';
import useQuery from 'helpers/useQuery';
import { getTotalAttempts } from '../helpers';

import {
  multiplayerListStyles,
  NewChallengeCardStyles,
  availableOpponents,
} from 'constants/multiplayer';
import { subjectNames } from 'constants/products';
import { SUPPORT_URL } from './constants';
import ReadyGoSound from 'assets/audio/ready-go-v2.mp3';

import {
  SideNav,
  BackButton,
  Button,
  PopupModal,
  ErrorModal,
  BreakdownCP,
} from 'components/Shared';
import { NoCardLeftModal } from '../components';
import {
  AssignedCurriculum,
  MyFriendCardContent,
  ChallengeCardv2,
  IncomingChallengesButton,
  MatchButtonWithAnimation,
  MyClassmateContent,
  RecentOpponents,
  CloseIconButton,
} from './components';
import { RecentPopupContainer } from './components/RecentOpponents/RecentOpponents.styles';
import {
  Container,
  Header,
  Mascot,
  InfoSection,
  Main,
  MobileBottomBar,
} from './NewChallenge.styled';

// Helpers

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const NewChallenge = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [play] = useSound(ReadyGoSound, {
    interrupt: true,
    playbackRate: 4,
    volume: 0,
  });
  const query = useQuery();
  const subjectParam = query.get('subject');
  const lowercaseSubjectParam = isNil(subjectParam)
    ? 'math'
    : subjectParam.toLowerCase().trim();
  const subjectID = subjectNames[lowercaseSubjectParam];
  const { t, i18n } = useTranslation([
    'newChallenge',
    'common',
    'dailyChallengev2',
    'errorModal',
  ]);
  // redux states
  const { userID } = useSelector((state) => state.login);
  const { isLoading: isProductsLoading, products } = useSelector(
    (state) => state.plan
  );
  const { isDashboardv2 } = useDashboard();
  const [randomBot, setRandomBot] = useLocalStorage(
    'pcRandomBot',
    {},
    { validateUserId: true }
  );
  const hasRandomBot = randomBot?.subject === lowercaseSubjectParam;
  const error = null;
  // states
  const [chooseOpponent, setChooseOpponent] = useState('1');
  const [recentOpponentType, setRecentOpponentType] = useState(null);
  const [selectedPCOpponent, setSelectedPCOpponent] = useState({
    2: '',
    3: '',
    4: '',
  });
  const { remainingAttempts, reloadHandler: reloadRemainingAttempts } =
    usePeerChallengeAttempts(userID, subjectID);
  const [isOpeningPC, setIsOpeningPC] = useState(false);
  const [mobilePopup, setMobilePopup] = useState(false);
  const outsideClick = useClickOutside(() => setMobilePopup(false));

  const opponentId = selectedPCOpponent[chooseOpponent];
  const isOpponentIdValid = !isNil(opponentId) && !isEmpty(opponentId);
  const totalEnergyPerDay = getTotalAttempts(products, lowercaseSubjectParam);
  const energyRemaining = remainingAttempts.data ?? 0;
  const isLoading =
    isProductsLoading || remainingAttempts.status !== 'fulfilled';
  // pop up modal
  const [showModal, setShowModal] = useState(false);
  const [showErrorAccessModal, setShowErrorAccessModal] = useState(false);
  const [hasDesktopFirstLoad, setHasDesktopFirstLoad] = useState(false);
  const [hasMobileFirstLoad, setHasMobileFirstLoad] = useState(false);

  // responsive
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const breakdownCpTop = useResponsiveValue({
    desktop: '224px',
    mobile: '0px',
    tablet: '264px',
  });
  const subjectTitle =
    isTablet || isMobile ? '' : ` - ${capitalizeFirstLetter(subjectParam)}`;

  const checkPCStatus = useCallback(async () => {
    try {
      const enablePCStatus = await fetchWrapper(getPCStatus, {
        userID,
        subjectID,
      });
      return enablePCStatus;
    } catch (e) {
      return false;
    }
  }, [subjectID, userID]);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const setNewSelectedOpponentId = useCallback(
    (opponentType, newOpponentId) => {
      setSelectedPCOpponent((prevState) => ({
        ...prevState,
        [opponentType]: newOpponentId,
      }));
    },
    []
  );

  // UseEffects

  // Get list of Classes for current user
  const reloadHandler = () => {
    reloadRemainingAttempts();
  };

  const closeHandler = () => {
    setShowErrorAccessModal(false);
  };

  const energy = (remaining, totalEnergy) => {
    const usedEnergy = totalEnergy - remaining;
    if (remaining <= 0 || remaining === null) {
      return Array(totalEnergy).fill('empty');
    }
    const remainingEnergy = remaining > 0 ? Array(remaining).fill('full') : [];
    const used = usedEnergy > 0 ? Array(usedEnergy).fill('empty') : [];
    const result = [...remainingEnergy, ...used];
    return result;
  };
  const onStartChallengeHandler = async () => {
    if (!isOpponentIdValid) return;
    if (energyRemaining <= 0) {
      openModal();
      return;
    }
    const enabledStatus = await checkPCStatus();
    if (!enabledStatus) {
      setShowErrorAccessModal(true);
      return;
    }
    setIsOpeningPC(true);
    // Preload Ready Go Sound Effect
    play();
    dispatch(
      issuePeerChallenge({
        CurrentUserId: userID,
        OpponentId: opponentId,
        SubjectId: subjectID,
        IsRandom:
          chooseOpponent === '3' ||
          (chooseOpponent === '1' && recentOpponentType === true),
      })
    );
    try {
      const getUserInfoAction = await dispatch(
        getUserInfos({
          userIDs: [userID, opponentId],
          pageIndex: 0,
          pageSize: 2,
          cache: false,
        })
      );
      await unwrapResult(getUserInfoAction);
      setIsOpeningPC(false);
    } catch (err) {
      console.error(err.message);
      history.push(
        `/multiplayer/peer-challenge/question-view?id=${opponentId}&subject=${subjectParam}`
      );
      return;
    }
    history.push(
      `/multiplayer/peer-challenge/question-view?id=${opponentId}&subject=${subjectParam}`,
      { animation: [{ UserId: userID }, { UserId: opponentId }] }
    );
  };

  const opponentsList = [
    {
      id: '2',
      name: t('newChallenge:main.myClassmate', 'My Classmate'),
      borderColor: '#ECDA82',
      bgColor: '#FFFADF',
      headerColor: '#FFDA48',
      fontColor: '#2A1E00',
      content: (
        <MyClassmateContent
          subjectID={subjectID}
          opponentType={chooseOpponent}
          setOpponentType={setChooseOpponent}
          setNewSelectedOpponentId={setNewSelectedOpponentId}
        />
      ),
    },
    {
      id: '4',
      name: t('newChallenge:bff.title', 'My Friend'),
      borderColor: '#FFC87C',
      bgColor: '#FFF8EE',
      headerColor: '#FFC18D',
      fontColor: '#2A1E00',
      content: (
        <MyFriendCardContent
          opponentType={chooseOpponent}
          setOpponentType={setChooseOpponent}
          selectedOpponentID={opponentId}
          setNewSelectedOpponentId={setNewSelectedOpponentId}
        />
      ),
    },
    {
      id: '3',
      name: t('newChallenge:main.randomChallenger', 'Random Challenger'),
      borderColor: '#FFD08F',
      bgColor: '#FFF8EE',
      headerColor: '#FFC97E',
      fontColor: '#2E0A00',
      content: (
        <MatchButtonWithAnimation
          randomBotData={randomBot}
          setRandomBot={setRandomBot}
          subject={lowercaseSubjectParam}
          setChooseOpponent={setChooseOpponent}
          selectedOpponent={opponentId}
          setNewSelectedOpponentId={setNewSelectedOpponentId}
        />
      ),
    },
  ];
  const opponents = opponentsList.filter((item) =>
    availableOpponents[lowercaseSubjectParam].includes(parseInt(item.id, 10))
  );

  // Event Handlers
  const onClickChallengerHandler = (selectedOpponentId) => {
    setChooseOpponent(selectedOpponentId);
  };

  const onClickRandomChallengerHandler = () => {
    if (!randomBot?.data?.Id) return;
    setChooseOpponent('3');
    setNewSelectedOpponentId('3', randomBot.data.Id);
  };

  if (isNil(subjectParam))
    return (
      <Redirect
        to={isDashboardv2 ? '/dashboard?view=koochallenge' : '/multiplayer'}
      />
    );

  const infoSectionContent = (isPopup) => (
    <InfoSection isPopup={isPopup} className={isPopup ? 'popup' : ''}>
      <IncomingChallengesButton
        isPopup={isPopup}
        onOpenMenu={() => setMobilePopup(true)}
        subjectParam={lowercaseSubjectParam}
        userID={userID}
      />
      <div
        className="history"
        onClick={() =>
          history.push(
            `/multiplayer/peer-challenge/history?subject=${subjectParam}`
          )
        }
      >
        <img
          src={
            multiplayerListStyles[subjectParam.toLowerCase().trim()].historyIcon
          }
          alt="history"
        />
        <p>{t('newChallenge:header.history', 'History')}</p>
      </div>
      <a
        className="rules"
        href={SUPPORT_URL[i18n.language] || SUPPORT_URL.en}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={
            multiplayerListStyles[subjectParam.toLowerCase().trim()].rulesIcon
          }
          alt="Rules"
        />
        <p>{t('newChallenge:header.rules', 'Rules')}</p>
      </a>
    </InfoSection>
  );

  return (
    <>
      <PopupModal show={showModal} hide={closeModal}>
        <NoCardLeftModal
          setShowModal={setShowModal}
          totalEnergyPerDay={totalEnergyPerDay}
        />
      </PopupModal>

      <Container>
        <SideNav show={mobilePopup}>
          <RecentPopupContainer ref={outsideClick}>
            {infoSectionContent(true)}
            <CloseIconButton
              size="32px"
              color="#fff"
              padding="8px"
              onClick={() => setMobilePopup(false)}
              btnBgColor="#b4b4b4"
            />
          </RecentPopupContainer>
        </SideNav>
        <Header error={!isNil(error)} subject={subjectParam ?? 'math'}>
          <div className="btn-section">
            <BackButton
              isResponsive
              top="5.5rem"
              btnBgColor={
                multiplayerListStyles[subjectParam.toLowerCase().trim()]
                  .backBtnBgColour
              }
              backText={
                isDashboardv2
                  ? t('common:back', 'Back')
                  : t('newChallenge:header.back', 'Back to Mutiplayer')
              }
              onClick={() =>
                history.push(
                  isDashboardv2
                    ? '/dashboard?view=koochallenge'
                    : '/multiplayer'
                )
              }
              padding="0.3rem 1.1rem"
            />
          </div>
          {multiplayerListStyles[subjectParam.toLowerCase().trim()].mascot.map(
            (item) => (
              <Mascot
                key={item.no}
                src={item.img}
                alt=""
                space={item.space}
                position={item.position}
              />
            )
          )}
          {!isLoading && (
            <>
              <div className="title">
                <p className="main-title">
                  {`${t(
                    'newChallenge:header.peerChallenge',
                    'Peer Challenge'
                  )}${subjectTitle}`}
                </p>
                <p className="sub-title">
                  {t(
                    'newChallenge:header.youHave',
                    { number: energyRemaining },
                    `You have ${energyRemaining} challenges remaining today`
                  )}
                </p>
                <div className="remaining-cards">
                  {remainingAttempts.status === 'fulfilled' &&
                    energy(energyRemaining, totalEnergyPerDay).map(
                      (item, index) => (
                        <img
                          key={index}
                          src={
                            item === 'full'
                              ? multiplayerListStyles[
                                  subjectParam.toLowerCase().trim()
                                ].energyIcon.fullShadow
                              : multiplayerListStyles[
                                  subjectParam.toLowerCase().trim()
                                ].energyIcon.emptyShadow
                          }
                          alt="energy"
                        />
                      )
                    )}
                </div>
              </div>
            </>
          )}
          {infoSectionContent(false)}
        </Header>
        <Main data-cy="new-challenge-container">
          {isDashboardv2 && lowercaseSubjectParam === 'math' && !isMobile && (
            <BreakdownCP
              target="pc"
              style={{
                position: 'absolute',
                right: '10vw',
                top: breakdownCpTop,
              }}
              hasFirstLoad={hasDesktopFirstLoad}
              setHasFirstLoad={setHasDesktopFirstLoad}
            />
          )}
          <>
            <div className="title">
              {t('newChallenge:main.challengeFriend', 'Choose your challenger')}
            </div>
            <div className="opponent-container">
              <div className="opponent-side">
                <RecentOpponents
                  userID={userID}
                  subjectParam={lowercaseSubjectParam}
                  selectedOpponentId={opponentId}
                  opponentType={chooseOpponent}
                  setOpponentType={setChooseOpponent}
                  setNewSelectedOpponentId={setNewSelectedOpponentId}
                  setRecentOpponentType={setRecentOpponentType}
                />
              </div>
              <div className="opponent-center">
                {opponents.map((opponent) => (
                  <ChallengeCardv2
                    key={opponent.id}
                    id={opponent.id}
                    styles={NewChallengeCardStyles[lowercaseSubjectParam]}
                    subject={lowercaseSubjectParam}
                    selectedOpponentTypeId={chooseOpponent}
                    title={opponent.name}
                    iconImg={
                      NewChallengeCardStyles[lowercaseSubjectParam]
                        .iconThumbnails[opponent.id]
                    }
                    avatarImg={
                      opponent.id === '3' && hasRandomBot
                        ? randomBot?.data?.UserAvatarImage
                        : null
                    }
                    onClickHandler={() => {
                      // eslint-disable-next-line no-unused-expressions
                      opponent.id === '3' && hasRandomBot
                        ? onClickRandomChallengerHandler()
                        : onClickChallengerHandler(opponent.id);
                    }}
                  >
                    {opponent.content}
                  </ChallengeCardv2>
                ))}
              </div>
              <div className="opponent-side" />
            </div>
            <div className="btn-section">
              <Button
                dataCy="start-challenge-btn"
                isDisabled={isOpeningPC || !isOpponentIdValid || isLoading}
                variant={!isOpponentIdValid ? 'disable' : 'primary'}
                fontSize="25px"
                fontWeight="600"
                width="300px"
                onClick={onStartChallengeHandler}
              >
                {t('newChallenge:main.startChallenge', 'Start Challenge')}
              </Button>
              {!isMobile && (
                <AssignedCurriculum
                  subject={lowercaseSubjectParam}
                  hasFirstLoad={hasDesktopFirstLoad}
                  setHasFirstLoad={setHasDesktopFirstLoad}
                />
              )}
            </div>
          </>
          {showErrorAccessModal && (
            <ErrorModal
              closeHandler={closeHandler}
              backPage={
                isDashboardv2 ? '/dashboard?view=koochallenge' : '/dashboard'
              }
              errorMessage={t(
                'newChallenge:modal.notAvailable',
                'Peer Challenge is not available during this time'
              )}
            />
          )}
          {remainingAttempts.status === 'rejected' && (
            <ErrorModal
              reloadAction={reloadHandler}
              errorMessage={remainingAttempts.error}
            />
          )}
        </Main>
      </Container>
      {lowercaseSubjectParam === 'math' && isMobile && (
        <MobileBottomBar className="pc">
          <AssignedCurriculum
            subject={lowercaseSubjectParam}
            hasFirstLoad={hasMobileFirstLoad}
            setHasFirstLoad={setHasMobileFirstLoad}
          />
          {isDashboardv2 && lowercaseSubjectParam === 'math' && (
            <BreakdownCP
              target="pc"
              hasFirstLoad={hasMobileFirstLoad}
              setHasFirstLoad={setHasMobileFirstLoad}
            />
          )}
        </MobileBottomBar>
      )}
    </>
  );
};

export default withSubscriptionCheck(NewChallenge);
