import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  transition: all 0.3s ease;
  .card-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: ${({ animated }) => (animated ? 'scale(1.05)' : 'none')};
    cursor: ${({ animated }) => (animated ? 'pointer' : 'none')};
  }
  @media (max-width: 768px) {
    width: ${({ noNeedResponseWidth }) =>
      noNeedResponseWidth ? 'auto' : '164px'};
  }
`;
