import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';

import { setVideoIsFullScreen } from 'store/kooClass/kooClassSlice';

import { sendEventTracking } from 'helpers/UserEventTracking';
import useQuery from 'helpers/useQuery';

import { ErrorResponse } from 'components/Shared';
import { StyledErrorContainer } from 'components/Shared/VideoPlayerModal/VideoPlayerModal.style';
import { Container } from './VideoPlayer.styles';

const VideoPlayer = ({ videoUrl, showVideo }) => {
  const ref = useRef(null);
  const playerRef = useRef(null);

  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const { currentProduct } = useSelector((state) => state.plan);
  const query = useQuery();
  const activityId = query.get('activityId');
  const videoId = videoUrl?.split('https://youtu.be/')[1] ?? '';
  const handleSendErrorTracking = (ecode) => {
    setErrorCode(ecode);
    sendEventTracking('kooclass', 'video_error', {
      sub: currentProduct?.subjectId,
      vi: videoId,
      ai: activityId,
      ecode,
    });
  };
  const handleError = () => {
    const videoElement = playerRef.current?.getInternalPlayer();
    if (videoElement.error) {
      handleSendErrorTracking(videoElement.error.code);
    }
    setHasError(true);
  };

  useEffect(() => {
    const videoRef = ref.current;
    const handleFullscreenChange = (e) => {
      dispatch(
        setVideoIsFullScreen(
          document.fullscreenElement === e.target ||
            document.webkitFullscreenElement === e.target
        )
      );
    };
    if (videoRef) {
      videoRef.addEventListener('fullscreenchange', handleFullscreenChange);
      videoRef.addEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      );
    }
    return () => {
      if (videoRef) {
        videoRef.removeEventListener(
          'fullscreenchange',
          handleFullscreenChange
        );
        videoRef.removeEventListener(
          'webkitfullscreenchange',
          handleFullscreenChange
        );
      }
    };
  }, [dispatch]);
  return (
    <>
      <Container showVideo={showVideo} data-cy="video-container" ref={ref}>
        {hasError && (
          <StyledErrorContainer>
            <a
              href={`https://youtu.be/${videoId}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                sendEventTracking('kooclass', 'video_error_click', {
                  sub: currentProduct?.subjectId,
                  vi: videoId,
                  ai: activityId,
                  ecode: errorCode,
                });
              }}
            >
              {t('common:video.openNewTab', 'Open in new tab')}
            </a>
          </StyledErrorContainer>
        )}
        {!isNil(videoUrl) ? (
          <ReactPlayer
            width="100%"
            height="calc(100vh - 59px)"
            url={videoUrl}
            ref={playerRef}
            controls
            loop={false}
            config={{
              youtube: {
                playerVars: {
                  rel: 0,
                  enablejsapi: 1,
                  origin: window.location.origin,
                },
              },
            }}
            onStart={() => {
              // EVENT TRACKING: watch video
              sendEventTracking('kooclass', 'video_view', {
                sub: currentProduct?.subjectId,
                vi: videoId,
                ai: activityId,
              });
            }}
            onReady={() => {
              const player = playerRef.current?.getInternalPlayer();
              if (player?.addEventListener) {
                player.addEventListener('onError', (e) => {
                  handleSendErrorTracking(e.data);
                  setHasError(true);
                });
              }
            }}
            onError={handleError}
          />
        ) : (
          <ErrorResponse error="Sorry, there is no url available" />
        )}
      </Container>
    </>
  );
};

export default VideoPlayer;
