import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getRewards, getTotalCPsBySubject } from 'store/dashboard/rewardsSlice';

import { Spinner } from 'react-bootstrap';
import Overlay from 'react-bootstrap/Overlay';

import { subjectNames } from 'constants/products';
import { SUPPORT_URL } from './constants';
import { KokoIcon } from 'assets/img/dashboardv2/dashboard';
import Info from 'assets/img/dashboardv2/info.svg';

import {
  StyledContainer,
  StyledItemContainer,
  StyledTooltip,
  rewardStyles,
} from './Rewards.styles';

const RewardItem = ({ id, icon, name, value, tooltip }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <StyledItemContainer>
      <div className="img-container">
        <img src={icon} alt="" className="reward-icon" />
        <span className="reward-value">{value}</span>
      </div>
      <span>
        <span className="reward-name">{name}</span>
        <img
          ref={target}
          onClick={() => setShow(!show)}
          className="reward-info"
          alt="reward-info"
          src={Info}
          width="18px"
        />
        <Overlay
          target={target.current}
          show={show}
          placement={tooltip.placement}
          rootClose
          onHide={() => setShow(false)}
        >
          <StyledTooltip id={`tooltip-${id}`}>
            <span className="tooltip-reward">{tooltip.text}</span>
          </StyledTooltip>
        </Overlay>
      </span>
    </StyledItemContainer>
  );
};

const Rewards = ({ types = [] }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['dashboard']);
  const { subject } = useSelector((state) => state.plan);
  const hasCP = types.includes(1);
  const {
    rewards,
    isLoading,
    isRewardsBySubjectLoading,
    currentSubjectTotalCPs,
  } = useSelector((state) => state.rewards);
  const rewardTypes = {
    1: {
      id: 1,
      name: t('sidepanel.Total CPs', 'Total CPs'),
      icon: rewardStyles[subject].icon ?? rewardStyles.math.icon,
      value:
        isLoading || isRewardsBySubjectLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          `${currentSubjectTotalCPs ?? ''}`
        ),
      tooltip: {
        placement: 'top',
        text: t(
          'tooltip.totalCP',
          'Challenge Points (CP) affect your ranking in leaderboards.'
        ),
      },
    },
    2: {
      id: 2,
      name: t('sidepanel.KoKo Credits', 'KoKo Credits'),
      icon: KokoIcon,
      value: isLoading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        `${rewards?.TotalKoko ?? ''}`
      ),
      tooltip: {
        placement: 'bottom',
        text: (
          <Trans i18nKey="dashboard:tooltip.kokoCredit">
            {`KoKo Credits are used to unlock `}
            <a
              href={SUPPORT_URL[i18n.language] || SUPPORT_URL.en}
              target="_blank"
              rel="noopener noreferrer"
            >
              certain features.
            </a>
          </Trans>
        ),
      },
    },
  };

  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch, subject]);
  useEffect(() => {
    // Fetch CPs for non-math subjects
    if (hasCP && subject?.toLowerCase().trim() !== 'math') {
      dispatch(
        getTotalCPsBySubject(subjectNames[subject?.toLowerCase().trim()])
      );
    }
  }, [dispatch, hasCP, subject]);
  return (
    <StyledContainer subject={subject}>
      {types.map((rewardTypeId) => (
        <RewardItem
          key={rewardTypes[rewardTypeId].id}
          icon={rewardTypes[rewardTypeId].icon}
          name={rewardTypes[rewardTypeId].name}
          value={rewardTypes[rewardTypeId].value}
          tooltip={rewardTypes[rewardTypeId].tooltip}
        />
      ))}
    </StyledContainer>
  );
};

export default Rewards;
