import React from 'react';
import { useTranslation } from 'react-i18next';
import PopupBg from '../../../imgs/popup.png';
import { Wrapper, ButtonClose, TextInfo, ButtonOK } from './PopupError.style';

const PopupError = ({ onClose, text }) => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>
      <img src={PopupBg} alt="popup" />
      <ButtonClose onClick={onClose} />
      <TextInfo>{text}</TextInfo>
      <ButtonOK onClick={onClose} href="#">
        {t('common:ok', 'OK')}
      </ButtonOK>
    </Wrapper>
  );
};

export default React.memo(PopupError);
