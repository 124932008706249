import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { WOD_LEVEL_OPTIONS } from '../../constants';

import { Button } from 'components/Shared';
import Spinner from 'react-bootstrap/Spinner';

import {
  Container,
  Header,
  Content,
  DescContainer,
  OptionsContainer,
  OptionCard,
  CheckedBullet,
  ButtonContainer,
} from './DifficultyOptions.styles';
import { isNil } from 'ramda';

const DifficultyOptions = ({
  cancelClick,
  okClick,
  selectedLevel,
  setSelectedLevel,
  currentLevel,
  level,
}) => {
  const { t } = useTranslation(['wod', 'common']);
  const { isLoading } = useSelector((state) => state.wod.wodLevelUpdate);

  return (
    <Container>
      <Header>{t('wod:levels.title', 'Select Your Level')}</Header>
      <Content>
        <DescContainer>
          <span>
            {t(
              `wod:levels.${selectedLevel?.translationKey}.name`,
              selectedLevel?.name
            )}
          </span>
          <p>
            {t(
              `wod:levels.${selectedLevel?.translationKey}.desc`,
              selectedLevel?.desc
            )}
          </p>
        </DescContainer>
        <OptionsContainer>
          {Object.entries(WOD_LEVEL_OPTIONS).map(([key, value]) => {
            return (
              <OptionCard
                key={key}
                onClick={() => setSelectedLevel(value)}
                isActive={selectedLevel?.id === value.id}
              >
                <div className="level-name">
                  <CheckedBullet isActive={selectedLevel?.id === value.id} />
                  <p className="name">
                    {t(`wod:levels.${value.translationKey}.name`, value.name)}
                    {selectedLevel?.id === value.id && (
                      <span className="name-desc">
                        {t(
                          `wod:levels.${value.translationKey}.desc`,
                          value.desc
                        )}
                      </span>
                    )}
                  </p>
                </div>
                <div className="level-icon">
                  <img src={value.icon} alt={value.name} />
                  <img src={value.miko} alt={value.name} className="miko" />
                </div>
              </OptionCard>
            );
          })}
        </OptionsContainer>
        <ButtonContainer>
          <Button
            variant="secondary3"
            className="action-btn"
            onClick={cancelClick}
          >
            {t('common:cancel', 'Cancel')}
          </Button>
          <Button
            variant="orange4"
            className="action-btn"
            onClick={okClick}
            isDisabled={
              isLoading ||
              (selectedLevel?.id === currentLevel?.id && !isNil(level))
            }
          >
            {t('common:ok', 'OK')}{' '}
            {isLoading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                style={{ marginLeft: '0.3rem' }}
              />
            )}
          </Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default DifficultyOptions;
