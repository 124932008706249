import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { useMediaQuery } from 'react-responsive';

import {
  getWordDefinition,
  getWordTranslation,
  getTranslationLanguages,
} from 'store/wod/wodSlice';

import { BUTTONS } from './constants';

import { ModalContentXButton, Spinner } from 'components/Shared';
import Voice from '../Voice';
import VocabCard from '../VocabCard';
import LanguageSelector from './LanguageSelector';

import { ReactComponent as TranslateActionIcon } from './translateActionIcon.svg';
import MeaningIcon from './MeaningIcon';
import TranslateIcon from './TranslateIcon';

import {
  StyledContainer,
  StyledMeaning,
  StyledTranslate,
  StyledInModalActions,
  StyledContent,
} from './MeaningAndTranslateModal.styles';

const MeaningAndTranslateModal = ({
  inModal,
  openModal,
  type,
  closeHandler,
  wordEntryId,
  imgUrl,
  word,
  wordClass,
  soundPlaying,
}) => {
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ maxWidth: 768 });

  const userSystemLang = navigator.language;
  const [language] = userSystemLang.split('-');
  const langConfig = language === 'zh' ? userSystemLang : language;

  const { data: languages, isLoading: languagesLoading } = useSelector(
    (state) => state.wod.languagesList
  );
  const { data: translation, isLoading: translationLoading } = useSelector(
    (state) => state.wod.wordTranslation
  );
  const { data, isLoading } = useSelector((state) => state.wod.wordDefinition);
  const [displayContent, setDisplayContent] = useState(type);
  const [selectedLang, setSelectedLang] = useState(langConfig);

  const loading = isLoading || languagesLoading;

  const displayOption = inModal ? displayContent : type;
  const langId = languages?.find(
    (lang) => lang.languageCode === selectedLang
  )?.languageId;

  const handleChangeLanguage = (value) => {
    setSelectedLang(value.languageCode);
  };

  const handleCloseModal = () => {
    closeHandler();
    if (inModal) setDisplayContent(type);
  };

  const changeContent = (contentButton) => {
    setDisplayContent(contentButton);
  };

  useEffect(() => {
    if (!isNil(wordEntryId)) {
      dispatch(getWordDefinition(wordEntryId));
    }
  }, [wordEntryId, dispatch]);

  useEffect(() => {
    if (
      !isNil(langId) &&
      !isNil(wordEntryId) &&
      displayOption === BUTTONS.TRANSLATE
    ) {
      dispatch(
        getWordTranslation({
          wordEntryId,
          languageId: langId,
        })
      );
    }
    // eslint-disable-next-line
  }, [langId, wordEntryId, displayOption]);

  useEffect(() => {
    if (isEmpty(languages) && displayOption === BUTTONS.TRANSLATE) {
      dispatch(getTranslationLanguages());
    }
    // eslint-disable-next-line
  }, [displayOption, dispatch]);

  return (
    <>
      <ModalContentXButton
        showClosedButton
        showModal={openModal}
        backdrop="static"
        containerMinWidth="60vw"
        closeModal={handleCloseModal}
      >
        <StyledContainer inModal={inModal}>
          {loading && <Spinner />}
          {!loading && (
            <>
              <StyledContent inModal={inModal}>
                {(displayOption === BUTTONS.MEANING ||
                  (!isTablet &&
                    (displayOption === BUTTONS.MEANING ||
                      displayOption === BUTTONS.TRANSLATE)) ||
                  inModal) && (
                  <div className="card-container">
                    <VocabCard
                      url={imgUrl}
                      hintText={`${data?.prefix}${data?.word}${data?.suffix}`}
                      textLength={
                        data?.wordText?.length +
                        data?.prefix?.length +
                        data?.suffix?.length
                      }
                      textDisplay={`${data?.prefix} ${data?.wordText} ${data?.suffix}`}
                      inModal
                    />
                    {isTablet && inModal && (
                      <StyledInModalActions>
                        {!isEmpty(data?.voiceOverUrl) && (
                          <Voice
                            audioLink={data?.voiceOverUrl}
                            className="voice-icon"
                            inModal
                            soundPlaying={soundPlaying}
                          />
                        )}
                        <MeaningIcon
                          isActive={displayContent === BUTTONS.MEANING}
                          onClick={() => changeContent(BUTTONS.MEANING)}
                        />
                        <TranslateIcon
                          isActive={displayContent === BUTTONS.TRANSLATE}
                          onClick={() => changeContent(BUTTONS.TRANSLATE)}
                        />
                      </StyledInModalActions>
                    )}
                  </div>
                )}

                {!isNil(data) && displayOption === BUTTONS.MEANING && (
                  <StyledMeaning>
                    <div className="title">{data?.wordText}</div>
                    <div className="modifier">{data?.wordClass}</div>
                    <ol>
                      <li>{data?.definition}</li>
                    </ol>
                  </StyledMeaning>
                )}
                {!isEmpty(languages) && displayOption === BUTTONS.TRANSLATE && (
                  <StyledTranslate inModal={inModal}>
                    <div className="translate-card english-card">
                      <div className="translate-selector">
                        <div className="english-button">English</div>
                      </div>
                      <div className="translate-content">
                        <div className="translate-title">{word}</div>
                        <div className="translate-modifier">{wordClass}</div>
                      </div>
                    </div>
                    <div className="translate-icon">
                      <TranslateActionIcon />
                    </div>
                    <div className="translate-card">
                      <div className="translate-selector">
                        <LanguageSelector
                          selectedLang={selectedLang}
                          onLanguageChange={handleChangeLanguage}
                        />
                      </div>
                      <div className="translate-content">
                        {translationLoading && (
                          <Spinner position="absolute" top="-4rem" left="50%" />
                        )}
                        {!translationLoading && !isNil(translation) && (
                          <>
                            <div className="translate-title">
                              {translation?.translatedText}
                            </div>
                            <div className="translate-modifier translate-bottom">
                              {translation.wordClassTranslationText}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </StyledTranslate>
                )}
              </StyledContent>
              {inModal && !isTablet && (
                <StyledInModalActions>
                  {!isEmpty(data?.voiceOverUrl) && (
                    <Voice
                      audioLink={data?.voiceOverUrl}
                      className="voice-icon"
                      inModal
                      soundPlaying={soundPlaying}
                    />
                  )}
                  <MeaningIcon
                    isActive={displayContent === BUTTONS.MEANING}
                    onClick={() => changeContent(BUTTONS.MEANING)}
                  />
                  <TranslateIcon
                    isActive={displayContent === BUTTONS.TRANSLATE}
                    onClick={() => changeContent(BUTTONS.TRANSLATE)}
                  />
                </StyledInModalActions>
              )}
            </>
          )}
        </StyledContainer>
      </ModalContentXButton>
    </>
  );
};

export default MeaningAndTranslateModal;
