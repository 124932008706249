import React from 'react';
import { useTranslation } from 'react-i18next';
import PopupBg from '../../imgs/popup.png';
import { Wrapper, ButtonClose, TextInfo, ButtonOK } from './PopupTimeout.style';

const PopoupTimeout = ({ onClose, onGame }) => {
  const { t } = useTranslation(['brainGames', 'common']);

  return (
    <Wrapper>
      <img src={PopupBg} alt="popup" />
      <ButtonClose type="button" onClick={onClose} />
      {onGame ? (
        <TextInfo>
          {t(
            'brainGames:timesUpModal.inGameText',
            'Time is up! Your 25 min game session has ended for today! Please come back again tomorrow for a new session. You can continue to enjoy other KooBits activities such as Daily Challenge and Self-Practice'
          )}
        </TextInfo>
      ) : (
        <TextInfo>
          {t(
            'brainGames:timesUpModal.unlockText',
            'You have already played 25 min today. Please come again tomorrow'
          )}
        </TextInfo>
      )}
      <ButtonOK type="button" onClick={onClose}>
        {t('common:ok', 'OK')}
      </ButtonOK>
    </Wrapper>
  );
};

export default React.memo(PopoupTimeout);
