import React, { useState, useRef } from 'react';
import { isEmpty } from 'ramda';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';

import { updateWodTutorial } from 'store/wod/wodSlice';

import { SpellTopBar, HintCountDown } from './components';
import { Voice, Hint, Tutorial } from '../components';

import { STATUS_UI } from './constant';
import { WOD_GAMES } from '../GameList/constants';
import { ANSWER_TYPE } from '../constant';

import { MikoHear, WrongIcon } from './imgs';

import {
  Container,
  Content,
  Input,
  HintAnswer,
  Button,
} from './SpellingBits.styles';

const SpellingBitsOnboarding = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['wod']);
  const containerRef = useRef(null);
  const { subject } = useParams();

  const audioLink =
    'https://s3.ap-southeast-1.amazonaws.com/dev-vocab.koobits.com/VoiceOver/0900cc82-de25-4d11-b670-2eedde0522cc.mp3';

  const status = ANSWER_TYPE.DEFAULT;

  const [answer, setAnswer] = useState('');
  const [hint, setHint] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);

  const removeCountDown = () => {
    setTimeout(() => {
      setShowCountDown(false);
      setAnswer('');
      setHint(false);
    }, 1000);
  };

  const steps = [
    {
      selector: '.spelling-bits-voice-icon',
      content: t(
        'wod:spellingBits.tutorial.audio',
        'Listen to the audio and spell the word.'
      ),
      reposition: true,
    },
    {
      selector: '.spelling-bits-step-2',
      secondTarget: ['#spelling-bits-hint-answer', '.spelling-bits-count-down'],
      content: t(
        'wod:spellingBits.tutorial.help',
        'Need help? Click here to show the word.'
      ),
      reposition: true,
      position: 'right',
      hideArrow: true,
      tooltipArrowStyle: css`
        position: absolute;
        width: 85px;
        left: -22%;
        top: 19px;
        transform: scaleX(-1);
        z-index: -1;
        @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
          transform: translateX(-50%);
          z-index: 1;
          left: 37%;
          top: -74px;
        }
      `,
    },
    {
      selector: '#spelling-bits-input',
      secondTarget: '.spelling-bits-voice-icon',
      content: t(
        'wod:spellingBits.tutorial.answer',
        'Spell and type the word inside the box.'
      ),
      reposition: true,
    },
    {
      position: 'center',
      content: '',
      link: `/words-of-the-day/${subject}/spelling-bits`,
    },
  ];

  const handleTutorialCallback = (index) => {
    if (index === 1) {
      setShowCountDown(true);
      setHint(true);
      setAnswer('challenge');
    }
    if (index === 2) {
      setShowCountDown(false);
      setHint(false);
      setAnswer('challenge');
    }
  };

  const updateTutorialStatusHandler = () => {
    dispatch(
      updateWodTutorial({
        gameType: WOD_GAMES.SPELLING_BITS.id,
        tutorialCompleted: true,
      })
    );
  };

  return (
    <>
      <Tutorial
        steps={steps}
        callback={handleTutorialCallback}
        closeHandler={updateTutorialStatusHandler}
      />
      <Container status={status} ref={containerRef}>
        <SpellTopBar progress={0} count={1} totalQn={10} isTutorial />
        <Content>
          <div className="action-btns">
            <Voice
              disabled={hint}
              audioLink={audioLink}
              className="spelling-bits-voice-icon"
              isTutorial
            />
            <Hint
              withHintSound={true}
              className="spelling-bits-step-2"
              disabled
            />
          </div>
          <img src={MikoHear} alt="miko" className="deco-miko" />
          {hint ? (
            <HintAnswer id="spelling-bits-hint-answer">
              <span className={showCountDown ? 'fade-out' : ''}>{answer}</span>
            </HintAnswer>
          ) : (
            <Input
              onChange={(e) => setAnswer(e.target.value)}
              status={status}
              disabled={STATUS_UI[status].inputDisabled || hint}
              value={answer}
              id="spelling-bits-input"
            />
          )}
          {status === 'wrong' && (
            <span className="wrong-icon">
              <img src={WrongIcon} alt="X" />
            </span>
          )}
        </Content>
        {!hint && (
          <Button disabled={isEmpty(answer)}>
            {STATUS_UI[status].btnText}
          </Button>
        )}
        {showCountDown && (
          <HintCountDown
            duration={3}
            clearHandler={removeCountDown}
            className="spelling-bits-count-down"
          />
        )}
      </Container>
    </>
  );
};

export default SpellingBitsOnboarding;
