import styled, { css, keyframes } from 'styled-components';
import { ProgressBar } from 'react-bootstrap';
import { BackButton } from 'components/Shared';

import { ANSWER_TYPE } from '../constant';

const BLOCK_WIDTH = 12;

const ansStyle = {
  [ANSWER_TYPE.DEFAULT]: {
    bg: '#FFC14A',
    color: 'black',
  },
  [ANSWER_TYPE.CORRECT]: {
    bg: '#BEEA9C',
    color: 'black',
  },
  [ANSWER_TYPE.WRONG]: {
    bg: '#FFE0E2',
    color: '#F44336',
  },
};

const fadeIn = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  @media (max-width: 910px) {
    flex-direction: column;
  }
`;

export const StyledLoadingMessage = styled.div`
  margin-top: 1rem;
  font-weight: 600;
  font-family: Linotte;
  font-size: 22px;
  color: white;
  span {
    color: #ffa238;
    &.miko {
      font-size: 36px;
      font-weight: 700;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 50%;
  max-height: unset;
  justify-content: center;
  background-color: #232123;
  animation: ${({ isLoading, noNeedLoading }) =>
    isLoading
      ? 'none'
      : css`
          ${fadeIn} ${noNeedLoading ? 0 : 1}s ease-in-out forwards
        `};
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.05) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: ${BLOCK_WIDTH}px ${BLOCK_WIDTH}px;
  position: relative;
  @media (max-width: 910px) {
    max-width: 100%;
    padding-bottom: 4rem;
    padding-top: 2rem;
  }
  &::before {
    content: '';
    position: absolute;
    width: ${BLOCK_WIDTH * 9}px;
    height: ${BLOCK_WIDTH * 11}px;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(
          rgba(255, 255, 255, 0.15) 12px,
          transparent 12px
        )
        ${BLOCK_WIDTH * 2}px ${BLOCK_WIDTH * 3}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.11) 12px, transparent 12px)
        ${BLOCK_WIDTH * 4}px ${BLOCK_WIDTH * 7}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.17) 12px, transparent 12px)
        ${BLOCK_WIDTH * 5}px ${BLOCK_WIDTH * 4}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.11) 12px, transparent 12px)
        ${BLOCK_WIDTH * 6}px ${BLOCK_WIDTH * 6}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.1) 12px, transparent 12px)
        ${BLOCK_WIDTH * 7}px ${BLOCK_WIDTH * 8}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.15) 12px, transparent 12px)
        ${BLOCK_WIDTH * 8}px ${BLOCK_WIDTH * 1}px / 12px 12px no-repeat;
  }
  &::after {
    content: '';
    position: absolute;
    width: ${BLOCK_WIDTH * 11}px;
    height: ${BLOCK_WIDTH * 8}px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(
          rgba(255, 255, 255, 0.15) 12px,
          transparent 12px
        )
        ${BLOCK_WIDTH * 0}px ${BLOCK_WIDTH * 3}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.15) 12px, transparent 12px)
        ${BLOCK_WIDTH * 3}px ${BLOCK_WIDTH * 4}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.15) 12px, transparent 12px)
        ${BLOCK_WIDTH * 6}px ${BLOCK_WIDTH * 1}px / 12px 12px no-repeat;
  }
`;

export const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: 30px;
  top: 28px;
  @media (max-width: 910px) {
    display: none;
  }
`;

export const QnContainer = styled.div`
  position: relative;
  .legend {
    position: absolute;
    top: 100%;
    width: 100%;
  }
`;

export const Card = styled.div`
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-repeat: no-repeat;
  max-height: calc(100dvh - 62px - 223px);
  background-position: center;
  aspect-ratio: 512/716;
  max-width: 100%;
  position: relative;
  margin: auto;
  .text {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: ${({ isOnboarding }) =>
      isOnboarding ? '1rem 1.8rem' : '0 1.8rem'};
    font-size: ${({ ch }) =>
      `clamp(1.5rem, ${Math.max(5 - ch * 0.2, 1)}rem, 5rem)`};
    font-family: 'Comic Sans MS', 'Comic Sans', cursive;
    font-weight: bold;
    word-break: break-word;
    overflow-wrap: anywhere;
    white-space: normal;
    text-align: center;
    margin-bottom: 0;
    line-height: 1.3;
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 5px;
    flex-wrap: wrap;
    .answer {
      display: flex;
      align-items: baseline;
      justify-content: center;
      gap: 0.3rem;
      flex-wrap: ${({ hintLength }) => hintLength > 1 && 'wrap'};
      font-size: ${({ ch }) =>
        `clamp(1.5rem, ${Math.max(5 - ch * 0.2, 1)}rem, 5rem)`};
      width: 100%;
    }
  }
  @media (max-width: 1024px) {
    margin: 0 120px;
    .text {
      font-size: ${({ ch }) =>
        `clamp(1rem, ${Math.max(4 - ch * 0.18, 1)}rem, 4rem)`};
      .answer {
        font-size: ${({ ch }) =>
          `clamp(1rem, ${Math.max(4 - ch * 0.18, 1)}rem, 4rem)`};
      }
    }
  }
  @media (max-width: 960px) {
    .text {
      font-size: ${({ ch }) =>
        `clamp(1rem, ${Math.max(3 - ch * 0.12, 1)}rem, 3rem)`};
      .answer {
        font-size: ${({ ch }) =>
          `clamp(1rem, ${Math.max(3 - ch * 0.12, 1)}rem, 3rem)`};
      }
    }
  }
  @media (max-width: 915px) {
    .text {
      font-size: ${({ ch }) =>
        `clamp(1rem, ${Math.max(3 - ch * 0.15, 1)}rem, 3rem)`};
      .answer {
        font-size: ${({ ch }) =>
          `clamp(1rem, ${Math.max(3 - ch * 0.15, 1)}rem, 3rem)`};
      }
    }
  }
  @media (max-width: 910px) {
    margin: auto;
    max-height: calc(100dvh - 62px - 223px - 6rem);
    .text {
      font-size: ${({ ch }) =>
        `clamp(1rem, ${Math.max(5 - ch * 0.21, 1)}rem, 5rem)`};
    }
  }
  @media (max-width: 516px) {
    margin-right: 100px;
    margin-left: 40px;
  }
  @media (max-width: 415px) {
    .text {
      font-size: ${({ ch }) =>
        `clamp(1rem, ${Math.max(4 - ch * 0.2, 1)}rem, 4rem)`};
      .answer {
        font-size: ${({ ch }) =>
          `clamp(1rem, ${Math.max(4 - ch * 0.2, 1)}rem, 4rem)`};
      }
    }
  }
  @media (max-width: 405px) {
    max-height: calc(100dvh - 62px - 223px - 6rem);
  }

  @media (max-height: 680px) {
    .text {
      font-size: ${({ ch }) =>
        `clamp(1rem, ${Math.max(4 - ch * 0.2, 1)}rem, 4rem)`};
      .answer {
        font-size: ${({ ch }) =>
          `clamp(1rem, ${Math.max(4 - ch * 0.2, 1)}rem, 4rem)`};
      }
    }
  }
`;

export const StyledDashContainer = styled.span`
  display: flex;
  gap: clamp(2px, 2%, 6px);
  margin-top: 12px;
  width: ${({ remainingCount }) => remainingCount * 30}px;
  max-width: ${({ allLength, remainingCount }) =>
    (remainingCount / allLength) * 100}%;
  .dash {
    height: 4px;
    background-color: #707070;
    max-width: 30px;
    flex: 1;
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    .dash {
      height: 3px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.mobile}px) {
    .dash {
      height: 2px;
    }
  }
`;

export const ActionContainer = styled.div`
  position: absolute;
  right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  top: 0;
`;

export const StyledProgress = styled(ProgressBar)`
  height: 10px;
  border-radius: 10px;
  background-color: #606060;
  margin-bottom: 0.5rem;
  .progress-bar {
    background-color: #3fc61d;
    border-radius: 10px;
  }
`;

export const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  animation: ${({ isLoading, noNeedLoading }) =>
    isLoading
      ? 'none'
      : css`
          ${fadeIn} ${noNeedLoading ? 0 : 1}s ease-in-out forwards
        `};
  @media (max-width: 910px) {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

export const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #474240;
  align-items: center;
  gap: 1rem;
  color: white;
  height: 62px;
  padding: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  .back-btn {
    padding: 0.25rem 1.2rem;
    position: absolute;
    left: 1rem;
    @media (max-width: 910px) {
      background: transparent;
      left: 0.2rem;
      .button-text {
        display: none;
      }
    }
  }
  .question-progress {
    font-size: 14px;
    font-family: Nunito;
    span {
      font-weight: bold;
      font-size: 24px;
      display: inline-block;
      margin-right: 4px;
    }
  }
`;

export const AnswerContainer = styled.div`
  background: ${({ answerType }) => ansStyle[answerType].bg};
  height: -webkit-fill-available; /* Safari/WebKit */
  height: -moz-available; /* Firefox */
  height: -o-available; /* Opera (rarely needed) */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12rem 10%;
  position: relative;
  .answer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .form-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .input-container {
    width: 100%;
    border-radius: 11px;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    position: relative;
    svg {
      position: absolute;
      right: -2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  input {
    border: none;
    outline: none;
    font-size: calc((90 / 1366) * 100vw);
    padding: 1rem 2rem;
    width: 100%;
    line-height: 1;
    font-family: 'Comic Sans', cursive;
    color: ${({ answerType }) => ansStyle[answerType].color};
    font-weight: bold;
    background-color: transparent;
    position: relative;
    z-index: 2;
    user-select: text;
    -webkit-user-select: text;
    -webkit-tap-highlight-color: transparent;
    overflow-x: auto;
    pointer-events: auto;
  }
  .next-button {
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 1;
    svg {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 910px) {
    padding: 1.5rem 10%;
  }
`;
