export const WOD_GAME_TYPE = {
  KOOCARDS: 1,
  SPELLING_BITS: 2,
};

export const ANSWER_TYPE = {
  DEFAULT: 2,
  WRONG: 0,
  CORRECT: 1,
};

export const WOD_STATUS = {
  NEW: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  RETRY: 4,
  LOCKED: 5,
};

export const WORD_CREATION_TYPE = {
  NEW: 1,
  REPLACE: 2,
};

export const WORD_GENERATION_DATE_TYPE = {
  TODAY: 1,
  TOMORROW: 2,
};

export const WORD_CREATION_STATUS = {
  IN_PROGRESS: 2,
  FAILED: 3,
  COMPLETED: 4,
};

export const WOD_ERROR_CODE = {
  NO_WOD_FOUND: 'WG009',
};
