import handleResponse, { handleResponseV2 } from 'helpers/handleAPIResponse';
import axios from 'axios';
import store from 'store/index';
import { ELIGIBILITY_TYPE_IDS } from 'constants/products';

const trialV2Url = process.env.REACT_APP_TRIAL_SERVICE_V2_URL;

export const fetchCountryEligibleSubjects = (countryId) => {
  return axios
    .get(
      `${trialV2Url}countries/${countryId}/eligible-subjects?eligibilityTypeId=${ELIGIBILITY_TYPE_IDS.OTP}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
export const fetchStudentParentDetail = (userID) => {
  return fetch(`${trialV2Url}Students/${userID}/Parent`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'get parent detail failed'); // eslint-disable-line
    });
};

export const requestTrialConsentOTP = (rawBody) => {
  return axios
    .post(`${trialV2Url}Trials/Students/Request/Otp`, rawBody, {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    })
    .then(handleResponseV2)
    .catch((err) => {
      if (err?.response?.data) {
        throw new Error(
          err.response.data.detail ||
            err.response.data.title ||
            'Failed to request OTP'
        );
      }
      throw new Error('Failed to request OTP');
    });
};

export const verifyTrialConsentOTP = (rawBody) => {
  return axios
    .post(`${trialV2Url}Trials/Students/Verify/Otp`, rawBody, {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    })
    .then(handleResponseV2)
    .catch((err) => {
      if (err?.response?.data) {
        throw new Error(
          err.response.data.detail ||
            err.response.data.title ||
            'verify trial OTP failed'
        );
      }
      throw new Error('verify trial OTP failed');
    });
};

export const requestCheckOTPStatus = ({ otpTrialRegistrationId }) => {
  return axios
    .get(
      `${trialV2Url}trials/status?typeid=${ELIGIBILITY_TYPE_IDS.OTP}&id=${otpTrialRegistrationId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      return err.response.data;
    });
};
export const requestCountries = () => {
  return axios
    .get(`${trialV2Url}Countries`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const requestOTPEligibility = (params) => {
  return axios
    .get(
      `${trialV2Url}Trials/otp/${params.userId}/eligibility/country/${params.countryId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      throw new Error(err);
    });
};
