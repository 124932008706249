import { useMediaQuery } from 'react-responsive';
import React, { useState, useEffect } from 'react';

import { Container, Second, CircleContainer } from './HintCountDown.styles';

const HintCountDown = ({ duration, clearHandler, className }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [timeDisplay, setTimeDisplay] = useState(duration);
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (timeLeft <= 0) {
      clearHandler();
    }

    if (timeLeft === duration) {
      setTimeLeft((prev) => prev - 1);
      setTimeDisplay(duration);
    } else {
      const interval = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
        setTimeDisplay((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }

    // eslint-disable-next-line
  }, [timeLeft, clearHandler]);

  // Calculate progress (0 to 100)
  const progress = (timeLeft / duration) * 100;

  const radius = isTablet ? 70 : 85; // Radius of the circle
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <Container className={className}>
      <CircleContainer
        width={isTablet ? '175' : '200'}
        height={isTablet ? '175' : '200'}
      >
        {/* Background Circle */}
        <circle
          cx={isTablet ? '80' : '100'}
          cy={isTablet ? '80' : '100'}
          r={radius}
          fill="none"
          stroke="#EAA727"
          strokeWidth="10"
        />
        {/* Progress Circle */}
        <circle
          cx={isTablet ? '120' : '100'}
          cy={isTablet ? '80' : '100'}
          r={radius}
          fill="none"
          stroke="#ffffff"
          strokeWidth="10"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          transform={`rotate(-90 100 100)`}
          style={{ transition: 'stroke-dashoffset 1s linear' }}
        />
      </CircleContainer>
      <Second>{timeDisplay}</Second>
    </Container>
  );
};

export default HintCountDown;
