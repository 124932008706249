import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  getWodPreferences,
  getWodGameStatus,
  resetWodPreferences,
  createWords,
  updateWodLevel,
} from 'store/wod/wodSlice';

import { WOD_GAMES, WOD_LEVEL_OPTIONS } from './constants';
import {
  WOD_STATUS,
  WORD_CREATION_TYPE,
  WORD_GENERATION_DATE_TYPE,
} from '../constant';

import { BackButton, ModalContentXButton, PopupModal } from 'components/Shared';
import Header from 'components/Header';
import {
  ChangeLevelWarning,
  DifficultyOptions,
  GameCard,
  RestrictionModal,
} from './components';

import {
  Container,
  Title,
  LevelIcon,
  Content,
  LevelContainer,
} from './GameList.styles';

const GameList = () => {
  const { t } = useTranslation(['common', 'wod']);
  const { subject } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { userID } = useSelector((state) => state.login);
  const { wodGameStatus, wodPreferences } = useSelector((state) => state.wod);

  const [showLevelModal, setShowLevelModal] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(WOD_LEVEL_OPTIONS.SCHOOL);

  const gameList = useCallback(() => {
    for (const key in WOD_GAMES) {
      WOD_GAMES[key].status = wodGameStatus?.data?.find(
        (game) => game.gameType === WOD_GAMES[key].id
      )?.status;
    }

    return WOD_GAMES;
  }, [wodGameStatus.data]);

  // to check if the user has not start any game yet
  const allNew = wodGameStatus?.data?.every(
    (item) =>
      (item.dailyAttempts === 0 && item.status === WOD_STATUS.NEW) ||
      item.status === WOD_STATUS.LOCKED
  );

  // get current user level
  const currentLevel =
    WOD_LEVEL_OPTIONS[
      Object.keys(WOD_LEVEL_OPTIONS).find(
        (key) =>
          WOD_LEVEL_OPTIONS[key].id === wodPreferences?.data?.userWodLevel
      )
    ] ?? WOD_LEVEL_OPTIONS.SCHOOL;

  const onClickHandler = (id, url) => {
    const hasSeenTutorial = wodPreferences?.data?.tutorialsCompleted?.find(
      (tutorial) => tutorial.gameTypeId === id
    ).completed;

    if (isNil(hasSeenTutorial) || !hasSeenTutorial) {
      history.push(`/words-of-the-day/${subject}/${url}/tutorial`);
    } else {
      history.push(`/words-of-the-day/${subject}/${url}`);
    }
  };

  const updateLevelHandler = async (setModal) => {
    if (allNew) {
      await dispatch(
        updateWodLevel({
          userWodLevel: selectedLevel.id,
        })
      );
      setModal(false);
      await dispatch(getWodPreferences());
    }
  };

  const getPreferencesAndStatus = async () => {
    try {
      dispatch(getWodGameStatus());
      const preferencesAction = await dispatch(getWodPreferences());
      const preferenceRes = unwrapResult(preferencesAction);
      if (isNil(preferenceRes.userWodLevel)) {
        setShowLevelModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSelectedLevel(currentLevel);
  }, [currentLevel]);

  useEffect(() => {
    if (!isNil(userID)) {
      getPreferencesAndStatus();
      dispatch(
        createWords({
          wordCreationType: WORD_CREATION_TYPE.NEW,
          generationDateType: WORD_GENERATION_DATE_TYPE.TODAY,
        })
      );
    }
    return () => {
      dispatch(resetWodPreferences());
    };
    // eslint-disable-next-line
  }, [userID]);

  return (
    <>
      <Header toggleAccessMenu={false} />
      <Container>
        <ModalContentXButton
          showModal={showLevelModal}
          closeModal={() => {
            if (isNil(wodPreferences.data.userWodLevel)) {
              history.push('/dashboard?view=KooChallenge');
            }
            setShowLevelModal(false);
          }}
          backdrop="static"
          borderRadius="16px"
          containerMinWidth="max-content"
        >
          <DifficultyOptions
            cancelClick={() => {
              if (isNil(wodPreferences.data.userWodLevel)) {
                history.push('/dashboard?view=KooChallenge');
              }
              setShowLevelModal(false);
            }}
            okClick={() => {
              if (!allNew) {
                setShowLevelModal(false);
                setShowErrModal(true);
              } else if (currentLevel.id === 1) {
                setShowWarning(true);
                setShowLevelModal(false);
              } else {
                updateLevelHandler(setShowLevelModal);
              }
            }}
            currentLevel={wodPreferences.data && currentLevel}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
            level={wodPreferences?.data?.userWodLevel}
          />
        </ModalContentXButton>
        <PopupModal show={showErrModal} hide={() => setShowErrModal(false)}>
          <RestrictionModal clickHandler={() => setShowErrModal(false)} />
        </PopupModal>
        <PopupModal show={showWarning} hide={() => setShowWarning(false)}>
          <ChangeLevelWarning
            clickHandler={() => {
              updateLevelHandler(setShowWarning);
            }}
            secondaryClickHandler={() => {
              setShowWarning(false);
              setShowLevelModal(true);
            }}
          />
        </PopupModal>
        <Title>
          <BackButton
            btnBgColor="#F4A11B"
            backText={t('common:back', 'Back')}
            onClick={() => history.push('/dashboard?view=koochallenge')}
            className="wod-back-btn"
          />
          <LevelContainer
            onClick={() => {
              if (isNil(currentLevel)) {
                return;
              }
              setShowLevelModal(true);
            }}
            currentLevel={currentLevel}
          >
            <LevelIcon currentLevel={currentLevel} />
            <div className="level-name">
              {wodPreferences?.isLoading
                ? '...'
                : currentLevel &&
                  t(
                    `wod:levels.${currentLevel?.translationKey}.name`,
                    currentLevel?.name
                  )}
            </div>
          </LevelContainer>
        </Title>
        <Content>
          {Object.entries(gameList()).map(([key, game]) => {
            return (
              <GameCard
                key={key}
                data={game}
                clickHandler={() => onClickHandler(game.id, game.url)}
              />
            );
          })}
        </Content>
      </Container>
    </>
  );
};

export default GameList;
