import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  padding: 1rem 0.7rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  img {
    width: 100%;
  }
  @media (max-width: 1023px) {
    width: ${({ inModal }) => !inModal && '45.21px'};
    height: ${({ inModal }) => !inModal && '45.21px'};
  }
`;
