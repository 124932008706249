import styled from 'styled-components';

export const ProgressBarLine = styled.div`
  background: #606060;
  border-radius: 11px;
  width: 280px;
  height: 21px;
  @media (max-width: 500px) {
    width: 178px;
  }
`;

export const ProgressBarContent = styled.div`
  background: #ff7f00;
  height: 21px;
  border-radius: 11px;
  width: ${({ width }) => `${width}%`};
  position: relative;
  transition: width 1s ease-out;
  .white-line {
    background: rgba(255, 196, 0, 0.41);
    border-radius: 5px;
    height: 5px;
    position: absolute;
    width: calc(100% - 25px);
    top: 4.5px;
    left: 10px;
  }
`;
