import React, { useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';

import { WOD_GAME_TYPE } from '../constant';

import { getGameResult, getWodGameStatus } from 'store/wod/wodSlice';

import { Button, Spinner, ConfettiWrapper } from 'components/Shared';
import { ResultTopBar } from '../components';

import {
  Container,
  ResultList,
  Header,
  ListContainer,
  WordItem,
  ActionBtns,
} from './SpellingBitsResult.styles';

const SpellingBitsResult = () => {
  const { t } = useTranslation(['common', 'wod']);
  const history = useHistory();
  const { subject } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const showConfetti = state?.isGameFinish;

  const { userID, timezone } = useSelector((state) => state.login);
  const { isLoading, spellingBitsResult } = useSelector(
    (state) => state.wod.gameResult
  );

  useEffect(() => {
    if (!isNil(userID)) {
      dispatch(
        getGameResult({
          gameType: WOD_GAME_TYPE.SPELLING_BITS,
          inquiryDate: moment.tz(timezone).format('yyyy-MM-DD'),
        })
      );
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

  const goToCoverPage = () => {
    history.push(`/words-of-the-day/${subject}`);
  };

  const tryAgainHandler = async () => {
    try {
      await dispatch(getWodGameStatus());
      history.push(`/words-of-the-day/${subject}/spelling-bits`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <ResultTopBar game="Spelling Bits" backHandler={goToCoverPage} />
      {showConfetti && !isLoading && !isNil(spellingBitsResult) && (
        <ConfettiWrapper />
      )}
      <ResultList>
        <Header id="headerwod">Words of the Day</Header>
        <ListContainer>
          {isLoading && <Spinner />}
          {!isLoading && !isNil(spellingBitsResult) && (
            <>
              {spellingBitsResult?.gameWords?.map((item, index) => (
                <WordItem key={item.gameWordId}>
                  <span>{index + 1}.</span>
                  <p>{item.wordText}</p>
                </WordItem>
              ))}
              <ActionBtns>
                <Button
                  variant="secondary"
                  width={isMobile ? '80%' : '188px'}
                  className="result-btn"
                  style={{ display: 'flex', justifyContent: 'center' }}
                  onClick={tryAgainHandler}
                >
                  {t('common:playAgain', 'Play Again')}
                </Button>
                <Button
                  variant="orange4"
                  width={isMobile ? '80%' : '188px'}
                  className="result-btn"
                  style={{ display: 'flex', justifyContent: 'center' }}
                  onClick={goToCoverPage}
                >
                  {t('common:done', 'Done')}
                </Button>
              </ActionBtns>
            </>
          )}
        </ListContainer>
      </ResultList>
    </Container>
  );
};

export default SpellingBitsResult;
