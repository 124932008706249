import React, { useState, useEffect, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';

import {
  getPCCurriculum,
  getUserAvailableCurriculumList,
  postAssignPCCurriculum,
} from 'store/curriculum/curriculumSlice';

import { ErrorModal, PopupModal, Button } from 'components/Shared';
import SettingItem from '../SettingItem';

import { Container, Item } from '../common.styles';

const DISABLE_CHANGE_BUTTON =
  process.env.REACT_APP_DISABLED_PC_CURRICULUM_CHANGE === 'true';

const CurriculumPC = ({ isB2C }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['mySettings']);
  const { userID } = useSelector((state) => state.login);
  const {
    currentPCCurriculum,
    currentPCError,
    currentPCIsLoading,
    postAssignPCCurriculumError,
    postAssignPCCurriculumIsLoading,
    userAvailableCurriculumList,
  } = useSelector((state) => state.curriculum);
  const [showModal, setShowModal] = useState(false);
  const [currentCurriculum, setCurrentCurriculum] = useState(null);
  const [selectedCurriculum, setSelectedCurriculum] = useState('');
  const error =
    currentPCError ||
    userAvailableCurriculumList.error ||
    postAssignPCCurriculumError;
  const isLoading =
    currentPCIsLoading ||
    userAvailableCurriculumList.isLoading ||
    postAssignPCCurriculumIsLoading;

  useEffect(() => {
    if (isNil(currentPCCurriculum) && isNil(currentPCError)) {
      dispatch(getPCCurriculum({ userID }));
    }
  }, [currentPCCurriculum, currentPCError, dispatch, userID]);
  useEffect(() => {
    if (
      currentPCCurriculum &&
      !isEmpty(userAvailableCurriculumList.curriculumList)
    ) {
      const findCurrentCurriculum =
        userAvailableCurriculumList.curriculumList.find(
          (currItem) => currItem.CurriculumID === currentPCCurriculum
        );
      if (findCurrentCurriculum) {
        setSelectedCurriculum(findCurrentCurriculum);
        setCurrentCurriculum(findCurrentCurriculum);
      }
    }
  }, [currentPCCurriculum, userAvailableCurriculumList.curriculumList]);
  const handleSubmit = async () => {
    try {
      const assignAction = await dispatch(
        postAssignPCCurriculum({
          userID,
          curriculumID: selectedCurriculum.CurriculumID,
        })
      );
      unwrapResult(assignAction);
      const getPCCurriculumAction = await dispatch(getPCCurriculum({ userID }));
      unwrapResult(getPCCurriculumAction);
      setShowModal(false);
    } catch (e) {
      console.error(e.message);
    }
  };
  const handleReloadAction = () => {
    if (currentPCError) {
      dispatch(getPCCurriculum({ userID }));
    }
    if (userAvailableCurriculumList.error) {
      dispatch(getUserAvailableCurriculumList());
    }
    if (postAssignPCCurriculumError) {
      handleSubmit();
    }
  };
  return (
    <>
      <SettingItem
        name="PC-Curriculum-Math"
        content={currentCurriculum?.curriculumName}
        showChange={
          currentCurriculum?.SubjectID === 1 &&
          userAvailableCurriculumList.curriculumList.length > 0 &&
          isB2C
        }
        isDisabled={DISABLE_CHANGE_BUTTON}
        onClick={() => setShowModal(true)}
      />
      <PopupModal show={showModal} hide={() => setShowModal(false)}>
        <Container>
          {userAvailableCurriculumList.curriculumList.map((curriculum) => {
            return (
              <Item
                key={curriculum.CurriculumID}
                isSelected={
                  selectedCurriculum.CurriculumID === curriculum.CurriculumID
                }
                align="left"
                onClick={() => {
                  setSelectedCurriculum(curriculum);
                }}
              >
                <div className="tick" />
                <div>{curriculum.curriculumName}</div>
              </Item>
            );
          })}
          <Button
            variant="primary"
            fontSize="16px"
            padding="0.5rem 1.5rem"
            margin="1rem"
            onClick={handleSubmit}
            isDisabled={
              !selectedCurriculum ||
              isLoading ||
              selectedCurriculum.CurriculumID === currentPCCurriculum
            }
          >
            {isLoading
              ? t('common:submitLoading', 'Submit...')
              : t('confirm', 'Confirm')}
          </Button>
        </Container>
      </PopupModal>
      {!isNil(error) && (
        <ErrorModal errorMessage={error} reloadAction={handleReloadAction} />
      )}
    </>
  );
};
const checkCurrentSubjectHaveMath = (Component) => (props) => {
  const { products, isLoading, getProductsError } = useSelector(
    (state) => state.plan
  );
  const math = useMemo(() => {
    const haveMath = products?.filter(
      ({ subject, nonExpiredSubscriptionProducts }) =>
        subject?.toLowerCase().trim() === 'math' &&
        nonExpiredSubscriptionProducts.length > 0
    );
    const haveB2C = haveMath?.find(({ nonExpiredSubscriptionProducts }) =>
      nonExpiredSubscriptionProducts.find(
        ({ productType }) => productType.toLowerCase().trim() !== 'school'
      )
    );
    return {
      haveMath: haveMath && haveMath.length > 0,
      haveB2C: !isNil(haveB2C),
    };
  }, [products]);
  return !isLoading && !getProductsError && math.haveMath ? (
    <Component {...props} isB2C={math.haveB2C} />
  ) : null;
};
export default checkCurrentSubjectHaveMath(CurriculumPC);
