import React, { useState, useEffect, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import { getCurrentDailyChallenge } from 'store/dailyChallenge/dailyChallengeSlice';
import {
  getDCCurrentCurriculum,
  getUserAvailableCurriculumList,
  postAssignDCCurriculum,
} from 'store/curriculum/curriculumSlice';
import { SubmitModal, Button, PopupModal, ErrorModal } from 'components/Shared';
import SettingItem from '../SettingItem';
import { Container, Item } from '../common.styles';

const DISABLE_CHANGE_BUTTON =
  process.env.REACT_APP_DISABLED_DC_CURRICULUM_CHANGE === 'true';

const CurriculumDC = ({ isB2C }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['mySettings']);
  const {
    currentDCCurriculum,
    currentDCError,
    postAssignDCCurriculumError,
    postAssignDCCurriculumIsLoading,
    currentDCIsLoading,
    userAvailableCurriculumList,
  } = useSelector((state) => state.curriculum);
  const { currDailyChallenge, currDailyChallengeErr } = useSelector(
    (state) => state.dailyChallenge
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedCurriculum, setSelectedCurriculum] = useState('');
  const [currentCurriculum, setCurrentCurriculum] = useState(null);
  const [
    showChangeCurriculumWarningModal,
    setShowChangeCurriculumWarningModal,
  ] = useState(false);
  const error =
    currentDCError ||
    userAvailableCurriculumList.error ||
    postAssignDCCurriculumError ||
    currDailyChallengeErr;
  const isLoading =
    userAvailableCurriculumList.isLoading ||
    currentDCIsLoading ||
    postAssignDCCurriculumIsLoading;
  useEffect(() => {
    if (
      ((isEmpty(currDailyChallenge) && isNil(currDailyChallengeErr)) ||
        currDailyChallenge.SubjectID !== 1) &&
      isB2C
    ) {
      dispatch(getCurrentDailyChallenge());
    }
  }, [currDailyChallenge, currDailyChallengeErr, isB2C, dispatch]);

  useEffect(() => {
    if (isNil(currentDCCurriculum) && isNil(currentDCError)) {
      dispatch(getDCCurrentCurriculum());
    }
    // eslint-disable-next-line
  }, [currentDCCurriculum, currentDCError]);
  useEffect(() => {
    if (
      currentDCCurriculum &&
      !isEmpty(userAvailableCurriculumList.curriculumList)
    ) {
      const findCurrentCurriculum =
        userAvailableCurriculumList.curriculumList.find(
          (currItem) => currItem.CurriculumID === currentDCCurriculum
        );
      if (findCurrentCurriculum) {
        setSelectedCurriculum(findCurrentCurriculum);
        setCurrentCurriculum(findCurrentCurriculum);
      }
    }
  }, [currentDCCurriculum, userAvailableCurriculumList.curriculumList]);

  const handleSubmit = async () => {
    try {
      const assignAction = await dispatch(
        postAssignDCCurriculum({
          CurriculumID: selectedCurriculum.CurriculumID,
        })
      );
      unwrapResult(assignAction);
      const getDCCurrentCurriculumAction = await dispatch(
        getDCCurrentCurriculum()
      );
      unwrapResult(getDCCurrentCurriculumAction);
      setShowModal(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  const handleCheckSubmit = () => {
    const skippedOrCompletedQns =
      currDailyChallenge.UserQuestionSubmissions?.filter(
        (submission) =>
          submission.SubmissionModel !== null &&
          (submission.SubmissionModel.SubmissionResult === 1 ||
            submission.QuestionStatus === 4)
      );
    if (skippedOrCompletedQns?.length > 0) {
      setShowChangeCurriculumWarningModal(true);
    } else {
      setShowModal(true);
    }
  };
  const handleReloadAction = () => {
    if (currentDCError) {
      dispatch(getDCCurrentCurriculum());
    }
    if (userAvailableCurriculumList.error) {
      dispatch(getUserAvailableCurriculumList());
    }
    if (currDailyChallengeErr) {
      dispatch(getCurrentDailyChallenge());
    }
    if (postAssignDCCurriculumError) {
      handleSubmit();
    }
  };
  return (
    <>
      <SettingItem
        name="Curriculum-Math"
        content={currentCurriculum?.curriculumName}
        showChange={
          currentCurriculum?.SubjectID === 1 &&
          userAvailableCurriculumList.curriculumList.length > 0 &&
          isB2C
        }
        isDisabled={DISABLE_CHANGE_BUTTON}
        onClick={handleCheckSubmit}
      />
      <PopupModal show={showChangeCurriculumWarningModal} backdrop="static">
        <SubmitModal
          yesHandle={() => {
            setShowModal(true);
            setShowChangeCurriculumWarningModal(false);
          }}
          noHandle={() => {
            setShowChangeCurriculumWarningModal(false);
          }}
          header={t(
            'curriculum-math.warningModal.header',
            'Curriculum Setting'
          )}
          title={
            <>
              <div>
                {t(
                  'curriculum-math.warningModal.title',
                  'It seems that you have completed a Daily Challenge question for today.'
                )}
              </div>
              <div>
                {t(
                  'curriculum-math.warningModal.desc',
                  'Changes will only take place tomorrow. Do you want to proceed?'
                )}
              </div>
            </>
          }
        />
      </PopupModal>
      <PopupModal show={showModal} hide={() => setShowModal(false)}>
        <Container>
          {userAvailableCurriculumList.curriculumList.map((curriculum) => {
            return (
              <Item
                key={curriculum.CurriculumID}
                isSelected={
                  selectedCurriculum.CurriculumID === curriculum.CurriculumID
                }
                align="left"
                onClick={() => {
                  setSelectedCurriculum(curriculum);
                }}
              >
                <div className="tick" />
                <div>{curriculum.curriculumName}</div>
              </Item>
            );
          })}
          <Button
            variant="primary"
            fontSize="16px"
            padding="0.5rem 1.5rem"
            margin="1rem"
            onClick={handleSubmit}
            isDisabled={
              !selectedCurriculum ||
              isLoading ||
              selectedCurriculum.CurriculumID === currentDCCurriculum
            }
          >
            {isLoading
              ? t('common:submitLoading', 'Submit...')
              : t('confirm', 'Confirm')}
          </Button>
        </Container>
      </PopupModal>
      {!isNil(error) && (
        <ErrorModal errorMessage={error} reloadAction={handleReloadAction} />
      )}
    </>
  );
};
const checkCurrentSubjectHaveMath = (Component) => (props) => {
  const { products, isLoading, getProductsError } = useSelector(
    (state) => state.plan
  );
  const math = useMemo(() => {
    const haveMath = products?.filter(
      ({ subject, nonExpiredSubscriptionProducts }) =>
        subject?.toLowerCase().trim() === 'math' &&
        nonExpiredSubscriptionProducts.length > 0
    );
    const haveB2C = haveMath?.find(({ nonExpiredSubscriptionProducts }) =>
      nonExpiredSubscriptionProducts.find(
        ({ productType }) => productType.toLowerCase().trim() !== 'school'
      )
    );
    return {
      haveMath: haveMath && haveMath.length > 0,
      haveB2C: !isNil(haveB2C),
    };
  }, [products]);
  return !isLoading && !getProductsError && math.haveMath ? (
    <Component {...props} isB2C={math.haveB2C} />
  ) : null;
};
export default checkCurrentSubjectHaveMath(CurriculumDC);
