import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from 'components/Shared';
import Spinner from 'react-bootstrap/Spinner';

import { Container, Icon, ActionContainer } from './RestrictionModal.styles';

const RestrictionModal = ({ clickHandler, secondaryClickHandler }) => {
  const { t } = useTranslation(['common', 'wod']);
  const { isLoading } = useSelector((state) => state.wod.wodLevelUpdate);

  return (
    <Container>
      <Icon>!</Icon>
      <p>
        You will lose your current progress in “Start from Zero” when changing
        levels. Would you like to proceed?
      </p>
      <ActionContainer>
        <Button
          variant="secondary3"
          className="ok-btn"
          onClick={secondaryClickHandler}
          isDisabled={isLoading}
        >
          {t('common:no', 'No')}
        </Button>
        <Button
          variant="orange4"
          className="ok-btn"
          onClick={clickHandler}
          isDisabled={isLoading}
          disableColor="#ff6701"
        >
          {t('common:yes', 'Yes')}
          {isLoading && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginLeft: '0.3rem' }}
            />
          )}
        </Button>
      </ActionContainer>
    </Container>
  );
};

export default RestrictionModal;
