import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty, isNil } from 'ramda';
import useSound from 'use-sound';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

import { Spinner } from 'react-bootstrap';

import {
  getWordsList,
  updateHint,
  submitGameAnswer,
  getRetryWordsList,
  getWodGameStatus,
} from 'store/wod/wodSlice';

import useDCcorrectSound from 'hooks/useDCcorrectSound';

import animationData from 'assets/animation/lf30_editor_xakgefzo.json';
import { ReactComponent as BackIcon } from 'assets/img/icon-back.svg';

import { ErrorModal } from 'components/Shared';
import { SpellTopBar, HintCountDown } from './components';
import {
  Voice,
  Hint,
  Loading,
  ClaimKokoCredit,
  MeaningAndTranslation,
  MeaningAndTranslateModal,
} from '../components';

import { STATUS_UI } from './constant';
import { ANSWER_TYPE } from '../constant';

import { WOD_GAME_TYPE, WOD_STATUS } from '../constant';
import { useLoading } from 'hooks/useLoading';

import { MikoHear, WrongIcon } from './imgs';

import wrongSound from './wrong.mp3';

import {
  Container,
  Content,
  Input,
  HintAnswer,
  Button,
  LoadingContent,
  MeaningTranslateContainer,
} from './SpellingBits.styles';

const SpellingBits = () => {
  const { t } = useTranslation(['wod', 'common']);
  const dispatch = useDispatch();
  const history = useHistory();

  const { subject } = useParams();

  const [answer, setAnswer] = useState('');
  const [status, setStatus] = useState(ANSWER_TYPE.DEFAULT);
  const [hint, setHint] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [progressQn, setProgressQn] = useState(1);
  const [correctCount, setCorrectCount] = useState(0);
  const [disabledHint, setDisabledHint] = useState(false);

  const [showLoading, setShowLoading] = useState(true);
  const [claimKoko, setClaimKoko] = useState(false);
  const [showStartBtn, setShowStartBtn] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [displayModalType, setDisplayModalType] = useState(null);
  const [showMeaningModal, setShowMeaningModal] = useState(false);

  const { wordsList, wodGameStatus, submitResult } = useSelector(
    (state) => state.wod
  );
  const { userID, timezone } = useSelector((state) => state.login);

  const loading = wordsList?.isLoading || wodGameStatus?.isLoading;
  const { progress } = useLoading(loading);

  const spellingBitsStatus = wodGameStatus?.data?.find(
    (data) => data.gameType === WOD_GAME_TYPE.SPELLING_BITS
  );

  const currentList = wordsList?.spellingBitsList;

  const currentQn =
    currentList?.find((word) =>
      isFirstLoad ? !word.result : word.wordOrder === progressQn
    ) ?? null;

  const nextQn = currentList?.find((next) => next.wordOrder === progressQn + 1);

  const [play, { isPlaying, stop }] = useSound(
    isFirstLoad ? currentQn?.voiceOverUrl : nextQn?.voiceOverUrl,
    {
      interrupt: false,
    }
  );
  const [playHint, { isPlaying: isPlayingHint, stop: stopHint }] = useSound(
    currentQn?.voiceOverUrl,
    {
      interrupt: false,
    }
  );

  const [playWrongSound] = useSound(wrongSound);
  const { play: playCorrectSound, stop: stopCorrectSound } = useDCcorrectSound({
    hasSound: true,
  });

  const clickHandler = async () => {
    switch (status) {
      case ANSWER_TYPE.CORRECT: {
        if (progressQn === currentList.length) {
          history.push({
            pathname: `/words-of-the-day/${subject}/spelling-bits/result`,
            state: { isGameFinish: true },
          });
        } else {
          setStatus(ANSWER_TYPE.DEFAULT);
          setAnswer('');
          setProgressQn((prev) => prev + 1);
          stopCorrectSound();
          play();
        }
        return;
      }
      case ANSWER_TYPE.WRONG: {
        setStatus(ANSWER_TYPE.DEFAULT);
        return;
      }
      default: {
        setIsFirstLoad(false);
        setDisabledHint(true);
        try {
          const submitAction = await dispatch(
            submitGameAnswer({
              gameWordId: currentQn?.gameWordId,
              answer: answer.trim(),
              result:
                currentQn?.wordText?.trim()?.toLowerCase() ===
                answer?.trim()?.toLowerCase(),
            })
          );
          const res = unwrapResult(submitAction);

          if (res.gameResult) {
            setStatus(ANSWER_TYPE.CORRECT);
            if (res.koko > 0) setClaimKoko(true);
            if (correctCount !== currentList?.length)
              setCorrectCount((prev) => prev + 1);
            playCorrectSound();
          } else {
            playWrongSound();
            setStatus(ANSWER_TYPE.WRONG);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setDisabledHint(false);
        }
      }
    }
  };

  const hintHandler = () => {
    if (submitResult.isLoading) {
      return;
    }
    if (isPlaying) {
      stop();
    }
    if (isPlayingHint) {
      stopHint();
    }
    setHint(true);
    setAnswer(currentQn.wordText);
    setStatus(ANSWER_TYPE.DEFAULT);
    playHint();
    setShowCountDown(true);
    dispatch(updateHint(currentQn?.gameWordId));
  };

  const removeCountDown = () => {
    setTimeout(() => {
      setShowCountDown(false);
      setAnswer('');
      setHint(false);
    }, 1000);
  };

  const handleStart = () => {
    setIsTransitioning(true);
    setShowLoading(false);
  };

  const handleOpen = (contentButton) => {
    setDisplayModalType(contentButton);
    if (!showMeaningModal) {
      setShowMeaningModal(true);
    }
  };

  const handleClose = () => {
    setDisplayModalType(null);
    setShowMeaningModal(false);
  };

  const getSpellingBits = useCallback(() => {
    if (spellingBitsStatus?.status === WOD_STATUS.COMPLETED) {
      dispatch(getRetryWordsList({ gameType: WOD_GAME_TYPE.SPELLING_BITS }));
    } else {
      dispatch(
        getWordsList({
          gameType: WOD_GAME_TYPE.SPELLING_BITS,
          inquiryDate: moment.tz(timezone).format('yyyy-MM-DD'),
          status: spellingBitsStatus?.status,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, spellingBitsStatus]);

  useEffect(() => {
    if (!isNil(userID) && !isNil(wodGameStatus.data)) {
      getSpellingBits();
    }
    // eslint-disable-next-line
  }, [userID, getSpellingBits, spellingBitsStatus]);

  useEffect(() => {
    if (!isNil(userID) && isNil(wodGameStatus.data)) {
      dispatch(getWodGameStatus());
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  useEffect(() => {
    if (currentQn && isFirstLoad) {
      setProgressQn(currentQn.wordOrder);
      setCorrectCount(currentQn.wordOrder - 1);
    }
    // eslint-disable-next-line
  }, [currentQn]);

  // auto pronounce handling
  useEffect(() => {
    if (!showLoading) {
      setTimeout(() => {
        play();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoading]);

  // to show start btn on loading page
  useEffect(() => {
    if (progress === 100) {
      const start = setInterval(() => {
        setShowStartBtn(true);
      }, 1000);
      return () => clearInterval(start);
    }
  }, [progress]);

  return (
    <>
      {!isNil(wordsList?.isError) && (
        <ErrorModal
          errorMessage={wordsList?.isError}
          closeHandler={() => history.push(`/words-of-the-day/${subject}`)}
        />
      )}
      <Loading
        message={
          <LoadingContent>
            <p className="title">
              {t('wod:spellingBits.loading', 'Get ready to spell!')}
            </p>
            {!isEmpty(currentList) && (
              <div className="word-list">
                {currentList.map((word) => (
                  <p key={word.wordOrder}>{word.wordText}</p>
                ))}
              </div>
            )}
          </LoadingContent>
        }
        progress={progress}
        showStartBtn={showStartBtn}
        startHandler={handleStart}
        fadeOutAnimation={isTransitioning}
        startBtnDisabled={!isNil(wordsList?.isError)}
      />
      <Container status={status} loading={showLoading}>
        {claimKoko && (
          <ClaimKokoCredit
            showModal={claimKoko}
            setShowModal={setClaimKoko}
            game="Spelling Bits"
            resultUrl={`/words-of-the-day/${subject}/spelling-bits/result`}
          />
        )}
        <SpellTopBar
          progress={correctCount}
          count={progressQn}
          totalQn={currentList.length}
        />
        <form
          autoComplete="off"
          className="spell-form"
          onSubmit={(e) => {
            e.preventDefault();
            clickHandler();
          }}
        >
          <Content>
            <div className="action-btns">
              <Voice
                disabled={hint}
                audioLink={currentQn?.voiceOverUrl}
                soundPlaying={isPlaying || isPlayingHint}
              />
              <Hint
                onClick={hintHandler}
                disabled={
                  hint ||
                  status === ANSWER_TYPE.CORRECT ||
                  submitResult.isLoading ||
                  disabledHint
                }
                withHintSound={true}
              />
            </div>
            <img src={MikoHear} alt="miko" className="deco-miko" />
            {hint ? (
              <HintAnswer>
                <span className={showCountDown ? 'fade-out' : ''}>
                  {answer}
                </span>
              </HintAnswer>
            ) : (
              <Input
                onChange={(e) => setAnswer(e.target.value)}
                status={status}
                disabled={STATUS_UI[status].inputDisabled || hint}
                value={answer}
                autoComplete="off"
                autoCapitalize="off"
                type="text"
                autoCorrect="off"
                spellCheck="false"
              />
            )}
            {status === ANSWER_TYPE.WRONG && (
              <span className="wrong-icon">
                <img src={WrongIcon} alt="X" />
              </span>
            )}
            {status === ANSWER_TYPE.CORRECT && (
              <Lottie
                isClickToPauseDisabled
                options={{
                  loop: false,
                  autoplay: true,
                  animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                isStopped={false}
                isPaused={false}
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  zIndex: 1,
                }}
              />
            )}
          </Content>
          {!hint && (
            <Button
              disabled={isEmpty(answer) || submitResult?.isLoading}
              type="submit"
            >
              {t(
                `common:${STATUS_UI[status].textKey}`,
                STATUS_UI[status].btnText
              )}{' '}
              {status === ANSWER_TYPE.CORRECT && (
                <BackIcon className="arrow-icon" />
              )}
              {submitResult.isLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ borderWidth: '0.2rem', verticalAlign: 'baseline' }}
                />
              )}
            </Button>
          )}
        </form>
        {showCountDown && (
          <HintCountDown duration={3} clearHandler={removeCountDown} />
        )}
        {status === ANSWER_TYPE.CORRECT && (
          <>
            <MeaningTranslateContainer>
              <MeaningAndTranslation
                handleOpen={handleOpen}
                displayContent={displayModalType}
              />
            </MeaningTranslateContainer>
            <MeaningAndTranslateModal
              openModal={showMeaningModal}
              type={displayModalType}
              closeHandler={handleClose}
              wordEntryId={currentQn?.wordEntryId}
              imgUrl={currentQn?.imageUrl}
              word={currentQn?.wordText}
              wordClass={currentQn?.wordClass}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default SpellingBits;
