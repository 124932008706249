import styled, { css, keyframes } from 'styled-components';
import { STATUS_UI } from './constant';

const fadeIn = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div`
  background: ${({ status }) => STATUS_UI[status].bg};
  background-blend-mode: ${({ status }) => STATUS_UI[status].blendMode};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  opacity: 0;
  animation: ${({ loading, noNeedLoading }) =>
    loading
      ? 'none'
      : css`
          ${fadeIn} ${noNeedLoading ? 0 : 1}s ease-in-out forwards
        `};

  .arrow-icon {
    transform: rotate(180deg);
  }

  .spell-form {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @media (max-width: 768px) {
    background: ${({ status }) => STATUS_UI[status].bgMobile};
    background-blend-mode: ${({ status }) => STATUS_UI[status].blendModeMobile};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  position: relative;
  margin-top: 2rem;

  .action-btns {
    display: flex;
    gap: 1rem;
    position: relative;
    z-index: 3;
  }

  .deco-miko {
    position: absolute;
    margin-left: -24rem;
    margin-top: -15rem;
  }

  .wrong-icon {
    position: absolute;
    right: -2.5rem;
    bottom: 5.5rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    .deco-miko {
      display: none;
    }
    .wrong-icon {
      display: none;
    }
  }
`;

export const Input = styled.input`
  width: 725px;
  height: 200px;
  background: #ffffff;
  border: none;
  border-radius: 11px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
  outline: none;
  padding: 2rem;
  font-size: 120px;
  font-weight: 700;
  color: ${({ status }) => STATUS_UI[status].inputTextColor};
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  @media (max-width: 768px) {
    width: 85%;
    height: 100px;
    font-size: 56px;
  }
`;

export const HintAnswer = styled.div`
  min-width: 725px;
  height: 200px;
  background: #ffffff;
  border: none;
  border-radius: 11px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 120px;
  font-weight: 700;
  padding: 2rem;
  z-index: 1;
  display: flex;
  align-items: center;
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;

  .fade-out {
    animation: fadeOut 4s ease-in-out forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @media (max-width: 768px) {
    width: 85%;
    min-width: 0;
    height: 100px;
    font-size: 56px;
  }
`;

export const Button = styled.button`
  border-radius: 37px;
  background: #ff6701;
  border: 1px solid #ff5b00;
  padding: 0.3rem 1rem;
  color: #ffffff;
  width: 425px;
  font-size: 30px;
  font-weight: 600;
  margin-top: 1rem;
  &:hover {
    background: #ff5800;
  }
  &:disabled {
    opacity: 30%;
  }
  @media (max-width: 768px) {
    font-size: 26px;
  }
  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  .title {
    color: #ff8f10;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .word-list {
    display: flex;
    border-radius: 8px;
    background: #363636;
    color: #ffffff;
    padding: 1.5rem;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    p {
      margin-bottom: 0;
      font-size: 21px;
      font-weight: 600;
    }
  }
  @media (max-width: 500px) {
    .word-list {
      margin: 0 1rem;
    }
  }
`;

export const MeaningTranslateContainer = styled.div`
  position: absolute;
  right: 2rem;
  top: 7rem;
  @media (max-width: 768px) {
    top: unset;
    bottom: 2rem;
  }
`;
