import React from 'react';
import { useTranslation } from 'react-i18next';
import { history } from 'Routes';
import { BackButton } from 'components/Shared';
import { Container } from './Header.styles';

const Header = ({ title, noBack }) => {
  const { t } = useTranslation(['myProfile']);
  return (
    <Container>
      {!noBack && (
        <BackButton
          grey
          backText={t('myProfile:password.Back', 'Back')}
          styles={{
            color: '#505050',
            fontWeight: '600',
            position: 'absolute',
            background: 'none',
            left: '5%',
          }}
          onClick={() => {
            history.goBack();
          }}
        />
      )}
      <div className="title">{title}</div>
    </Container>
  );
};

export default Header;
