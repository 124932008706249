import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import {
  getProfileAvatars,
  updateAvatar,
  resetAvatarSuccess,
  resetError,
} from 'store/profileSettings/profileSettingsSlice';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';

import {
  ErrorModal,
  ResultModal,
  PopupModal,
  Spinner,
} from 'components/Shared';
import { Container, Header, ConfirmButton, Body } from './MyAvatar.styles';

const MyAvatar = () => {
  const { t } = useTranslation(['myAvatar']);
  const dispatch = useDispatch();
  const { isLoading, isErr, avatars, avatarUpdateSuccess } = useSelector(
    (state) => state.profileSettings
  );
  const [activeId, setActiveId] = useState(null);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    dispatch(getStudentDetails());
    if (!avatars.length) dispatch(getProfileAvatars());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (avatarUpdateSuccess) {
      setSuccessModal(true);
      dispatch(resetAvatarSuccess());
      dispatch(getStudentDetails());
    }
    // eslint-disable-next-line
  }, [avatarUpdateSuccess]);

  const updateProfileAvatar = () => {
    dispatch(updateAvatar(activeId));
  };

  const closeSuccessModal = () => setSuccessModal(false);

  const reloadHandler = () => {
    dispatch(getProfileAvatars());
  };

  const resetHanlder = () => {
    if (!isNil(isErr)) {
      dispatch(resetError());
    }
  };
  return (
    <Container>
      <PopupModal show={successModal} hide={closeSuccessModal}>
        <ResultModal
          btnText={t('myAvatar:modal.ok', 'OK')}
          onClick={closeSuccessModal}
          data
          desc={t(
            'myAvatar:modal.avatarMsg',
            'Your avatar has been changed successfully.'
          )}
        />
      </PopupModal>
      {!isEmpty(avatars) && (
        <Header>
          <div data-cy="title">
            {t('myAvatar:header.choose', 'Choose My Avatar')}
          </div>
          <div className="confirm-btn">
            <ConfirmButton
              onClick={() => updateProfileAvatar()}
              data-cy="confirm-btn"
              disabled={isNil(activeId)}
              type="submit"
            >
              {t('myAvatar:header.confirm', 'Confirm')}
            </ConfirmButton>
          </div>
        </Header>
      )}
      <Body data-cy="avatar-list">
        {isLoading && <Spinner />}
        {isErr && (
          <ErrorModal
            errorMessage={isErr}
            reloadAction={isEmpty(avatars) && reloadHandler}
            closeHandler={resetHanlder}
          />
        )}
        {avatars.map((item, i) => (
          <React.Fragment key={`${item.ProfileAvatarCategoryName}-${i}`}>
            <div className="category">
              {t(
                `category.${item.ProfileAvatarCategoryName}`,
                item.ProfileAvatarCategoryName
              )}
            </div>
            <div className="cat-row">
              {item.ProfileAvatarList.map((elem) => (
                <div
                  data-cy={elem.Id}
                  className={`img-container ${
                    activeId === elem.Id && 'active-img'
                  }`}
                  key={elem.Id}
                  onClick={() => setActiveId(elem.Id)}
                >
                  <img src={elem.Image} alt="avater-img" />
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </Body>
    </Container>
  );
};

export default MyAvatar;
