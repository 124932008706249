import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import { getUserAvailableCurriculumList } from 'store/curriculum/curriculumSlice';

import { Language, CurriculumDC, CurriculumPC } from './components';
import { StyledBox, StyledContainer } from './Settings.styles';

const Settings = () => {
  const dispatch = useDispatch();
  const { userAvailableCurriculumList } = useSelector(
    (state) => state.curriculum
  );
  useEffect(() => {
    if (
      isEmpty(userAvailableCurriculumList.curriculumList) &&
      isNil(userAvailableCurriculumList.error)
    ) {
      dispatch(getUserAvailableCurriculumList());
    }
    // eslint-disable-next-line
  }, [
    userAvailableCurriculumList.curriculumList,
    userAvailableCurriculumList.error,
  ]);
  return (
    <StyledContainer>
      <Language />
      <StyledBox>
        <CurriculumDC />
      </StyledBox>
      <StyledBox>
        <CurriculumPC />
      </StyledBox>
    </StyledContainer>
  );
};

export default Settings;
