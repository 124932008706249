import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';

import {
  updateChildDisplayName,
  updateChildLoginId,
  getParentDetails,
  reset,
} from 'store/parent/parentSettingsSlice';

import { compareValues } from 'helpers/compareValue';

import { Spinner } from 'components/Shared';
import { Card, DetailInfo, ProfileName } from '..';

import { StyledUpdateError } from './ChildAccountDetail.styles';

const ChildAccountDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    childDisplayNameUpdate,
    childLoginIdUpdate,
    isUpdateError,
    isUpdateLoading,
    isLoading,
    childrenProfile,
  } = useSelector((state) => state.parentSettings);
  const { userID } = useSelector((state) => state.login);

  const [selectedKidId, setSelectedKidId] = useState('');

  const [name, setName] = useState('');
  const [editName, setEditName] = useState(false);

  const [loginId, setLoginId] = useState('');
  const [editLoginId, setEditLoginId] = useState(false);

  const validation = (data, id) => {
    const loginIDFormat = /^[0-9a-zA-Z-_@.]+$/;
    const nameFormat = /^[0-9a-zA-Z\s]+$/;

    if (isEmpty(data) === true) {
      return 'This is a required field';
    }
    if (id === 1) {
      if (name && !name.match(nameFormat)) {
        return "Your child's name should not contain special characters";
      }
    }
    if (id === 2) {
      if (loginId && loginId.length < 6) {
        return 'Login ID should be at least 6 characters long';
      }
      if (loginId && !loginId.match(loginIDFormat)) {
        return 'Login ID should not contain spaces or special characters';
      }
      return '';
    }
    return '';
  };

  const sortChildren = childrenProfile?.slice().sort(compareValues('KidId'));

  useEffect(() => {
    if (childDisplayNameUpdate || childLoginIdUpdate)
      dispatch(getParentDetails(userID));
    setEditName(false);
    setEditLoginId(false);
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line
  }, [dispatch, childDisplayNameUpdate, childLoginIdUpdate]);

  const updateDetails = (title, id) => {
    // not working anymore
    if (title === 'Child Display Name') {
      dispatch(updateChildDisplayName({ KidID: id, DisplayName: name }));
      setName('');
    }
    if (title === 'Child Login ID') {
      dispatch(
        updateChildLoginId({
          KidID: id,
          NewLoginID: loginId,
        })
      );
      setLoginId('');
    }
  };
  const DETAIL_INFOS = (data) => [
    {
      id: 1,
      key: 1,
      title: 'Child Display Name',
      data: name,
      defaultValue: data.fullName,
      setValue: setName,
      editValue: editName,
      setEdit: setEditName,
      validation:
        editName && selectedKidId === data.studentUserId && validation(name, 1),
      editClick: () => {
        setEditName(true);
        setSelectedKidId(data.studentUserId);
        setName(data.fullName);
      },
    },
    {
      id: 1,
      key: 2,
      title: 'Child Login ID',
      data: loginId,
      defaultValue: data.loginName,
      setValue: setLoginId,
      editValue: editLoginId,
      setEdit: setEditLoginId,
      validation:
        editLoginId &&
        selectedKidId === data.studentUserId &&
        validation(loginId, 2),
      editClick: () => {
        setEditLoginId(true);
        setSelectedKidId(data.studentUserId);
        setLoginId(data.loginName);
      },
    },
    {
      id: 1,
      key: 3,
      title: 'Current Password',
      defaultValue: '******',
      editable: true,
      editClick: () => {
        history.push({
          pathname: '/parent/settings/change-password/child',
          state: { id: data.studentUserId },
        });
      },
    },
  ];
  return (
    <div>
      {isLoading && <Spinner />}
      {isUpdateError && (
        <StyledUpdateError>Update failed. {isUpdateError}</StyledUpdateError>
      )}
      {!isLoading &&
        sortChildren &&
        sortChildren.map((data) => (
          <React.Fragment key={data.studentUserId}>
            <Card firstItem>
              <ProfileName
                avatar={data.avatarUrl}
                displayName={data.fullName}
              />
            </Card>
            {DETAIL_INFOS(data).map((info) => (
              <Card key={info.key}>
                <DetailInfo
                  id={info.id}
                  account="child"
                  isLoading={isUpdateLoading}
                  title={info.title}
                  data={info.data}
                  defaultValue={info.defaultValue}
                  editable={info.editable}
                  onChange={(e) => {
                    if (info.setValue) {
                      info.setValue(e.target.value);
                    }
                  }}
                  edit={selectedKidId === data.studentUserId && info.editValue}
                  setEdit={info.setEdit}
                  validation={info.validation}
                  editClick={info.editClick}
                  cancelClick={() => {
                    setSelectedKidId('');
                    info.setEdit(false);
                    if (isUpdateError !== null) {
                      dispatch(reset());
                    }
                  }}
                  updateClick={() => {
                    updateDetails(info.title, data.studentUserId);
                    setSelectedKidId('');
                  }}
                />
              </Card>
            ))}

            <Card lastItem>
              <DetailInfo
                title="Level"
                defaultValue={`Primary ${data.userLevel}`}
                marginBottom
              />
              <DetailInfo
                title="School"
                defaultValue={data.schoolName ?? '-'}
              />
            </Card>
          </React.Fragment>
        ))}
    </div>
  );
};

export default ChildAccountDetail;
