import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import demoCard from './imgs/demo_card.png';
import { css } from 'styled-components';

import { ANSWER_TYPE } from '../constant';
import { WOD_GAMES } from '../GameList/constants';

import { updateWodTutorial } from 'store/wod/wodSlice';

import useIsBelowTablet from 'hooks/responsive/useIsBelowTablet';

import { Button } from 'components/Shared';
import { Modifier } from './components';
import { Hint, Voice, QnProgressBar, Tutorial } from '../components';

import {
  Container,
  Left,
  Right,
  StyledBackButton,
  ActionContainer,
  StyledProgress,
  TopBar,
  AnswerContainer,
  StyledDashContainer,
  Card,
  QnContainer,
} from './KooCards2.styles';

const currentAnswerType = ANSWER_TYPE.DEFAULT;
const TEXT = 'device';

const KooCardsOnboard = () => {
  const { t } = useTranslation(['wod', 'common']);
  const { subject } = useParams();
  const isBelowTablet = useIsBelowTablet();
  const history = useHistory();
  const dispatch = useDispatch();

  const isTablet = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  const [inputValue, setInputValue] = useState('');
  const [hintText, setHintText] = useState('d');
  const [step, setStep] = useState(1);

  const percentage = (1 / (TEXT.length - 1)) * (hintText.length - 1);

  const steps = [
    {
      selector: '#answerInput',
      secondTarget: '.imgContainer',
      position: 'bottom',
      content: t(
        'wod:kooCards.tutorial.keyIn',
        'Look at the card. Key in the answer.'
      ),
      type: 'koocards',
    },
    {
      selector: '.hint-progress',
      secondTarget: ['.text', '.hint'],
      reposition: true,
      content: t(
        'wod:kooCards.tutorial.help',
        'Need help? Click here to show more hints.'
      ),
      type: 'koocards',
      tooltipArrowStyle: css`
        position: absolute;
        width: 85px;
        left: 27%;
        top: -80px;
        transform: translate(-50%);
        @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
          left: unset;
          right: 7%;
        }
      `,
    },
    {
      selector: '#answerInput',
      secondTarget: '.imgContainer',
      content: t(
        'wod:kooCards.tutorial.answer',
        'Key in the correct answer here.'
      ),
      type: 'koocards',
    },
    {
      position: 'center',
      content: '',
      link: `/words-of-the-day/${subject.toLowerCase()}/koocards`,
      type: 'koocards',
    },
  ];

  if (step === 2) {
    if (hintText.length === 5) {
      setHintText('d');
    } else {
      setTimeout(() => {
        setHintText(TEXT.slice(0, hintText.length + 1));
      }, 1000);
    }
  }

  const handleTutorialCallback = (index) => {
    if (index) {
      setStep(index + 1);
    }
    if (index === 2) {
      setHintText('devi');
      setInputValue(TEXT);
    }
  };

  const updateTutorialStatusHandler = () => {
    dispatch(
      updateWodTutorial({
        gameType: WOD_GAMES.KOOCARDS.id,
        tutorialCompleted: true,
      })
    );
  };

  return (
    <>
      <Tutorial
        steps={steps}
        callback={handleTutorialCallback}
        closeHandler={updateTutorialStatusHandler}
      />
      <Container>
        {isTablet && (
          <TopBar>
            <div className="question-progress">
              <span>1</span>/10
            </div>
            <QnProgressBar progress={0} totalQn={10} />
          </TopBar>
        )}
        <Left>
          <StyledBackButton
            backText={t('common:back', 'Back')}
            btnBgColor="#404447"
            padding="0.3rem 1.2rem"
            onClick={() => history.push(`/words-of-the-day/${subject}`)}
          />
          <QnContainer>
            <Card
              url={demoCard}
              ch={TEXT.length}
              className="imgContainer"
              isOnboarding
            >
              <p className="text">
                {hintText}
                <StyledDashContainer
                  remainingCount={TEXT.length - hintText?.length}
                  allLength={TEXT.length}
                >
                  {new Array(TEXT.length - hintText?.length)
                    .fill('_')
                    .map((_, index) => (
                      <div className="dash" key={index} />
                    ))}
                </StyledDashContainer>
              </p>
            </Card>
            <Modifier className="legend" />
            <ActionContainer>
              <Hint className="hint koocards-action">
                <StyledProgress
                  now={100 - Math.floor(percentage * 100)}
                  className="hint-progress"
                />
              </Hint>
              <Voice
                audioLink="https://s3.ap-southeast-1.amazonaws.com/dev-vocab.koobits.com/VoiceOver/game_over.mp3"
                className="koocards-action"
              />
            </ActionContainer>
          </QnContainer>
        </Left>
        <Right>
          {!isTablet && (
            <TopBar>
              <div className="question-progress">
                <span>1</span>/10
              </div>
              <QnProgressBar progress={0} totalQn={10} />
            </TopBar>
          )}
          <AnswerContainer answerType={currentAnswerType}>
            <div className="input-container">
              <input
                value={inputValue}
                placeholder={'d'}
                disabled={currentAnswerType !== ANSWER_TYPE.DEFAULT}
                readOnly={true}
                type="text"
                autoComplete="off"
                inputMode="none"
                autoCapitalize="off"
                id="answerInput"
              />
            </div>
            <Button
              margin={isBelowTablet ? '1rem auto 0.75rem' : '2rem auto 0'}
              variant="primary"
              width="80%"
              disableColor="#ff7121"
              fontSize="24px"
            >
              {t('common:check', 'Check')}
            </Button>
          </AnswerContainer>
        </Right>
      </Container>
    </>
  );
};

export default KooCardsOnboard;
