import { isNil } from 'ramda';

const simplifiedChineseLocaleNames = {
  zh: 'zh',
  'zh-CN': 'zh-CN',
  'zh-Hans': 'zh-Hans',
  'zh-Hans-HK': 'zh-Hans-HK',
  'zh-Hans-MO': 'zh-Hans-MO',
  'zh-SG': 'zh-SG',
};
const traditionalHKChineseLocaleNames = {
  'zh-Hant': 'zh-Hant',
  'zh-HK': 'zh-HK',
  'zh-MO': 'zh-MO',
};
const traditionalTWChineseLocaleNames = {
  'zh-TW': 'zh-TW',
};

export const defaultLang = (lang, localeName, nativeName) => {
  if (lang === 'en') {
    return { name: 'English', code: 'en' };
  } else if (lang === 'id') {
    return { name: 'Indonesian', code: 'ba' };
  } else if (lang === 'zh') {
    if (!isNil(simplifiedChineseLocaleNames[localeName])) {
      return { name: '中文-简', code: 'zh_CN' };
    }
    if (!isNil(traditionalHKChineseLocaleNames[localeName])) {
      return { name: '中文 (香港)', code: 'zh_HK' };
    }
    if (!isNil(traditionalTWChineseLocaleNames[localeName])) {
      return { name: '中文 (臺灣)', code: 'zh_TW' };
    }
    return 'zh_CN';
  } else {
    return {
      name: nativeName,
      code: localeName,
    };
  }
};
