import React from 'react';
import { StyledContainer } from './DisplayText.styles';
const DisplayText = ({
  text,
  allLength,
  showKeyboard,
  className,
  viewWidth,
  display,
  inModal,
}) => {
  return (
    <StyledContainer
      allLength={allLength}
      textLength={text?.length}
      showKeyboard={showKeyboard}
      className={className}
      viewWidth={viewWidth}
      ch={display ? display?.length : text?.length}
      inModal={inModal}
    >
      <span>{display ?? text}</span>
    </StyledContainer>
  );
};

export default DisplayText;
