export const SUPPORT_URL = {
  en: 'https://support.koobits.com/hc/en-gb/articles/360018145852-Introducing-KoKo-Credits',
  zh_HK:
    'https://support.koobits.com/hc/zh-tw/articles/360018145852-KoKo-%E7%A9%8D%E5%88%86%E7%B0%A1%E4%BB%8B',
  zh_TW:
    'https://support.koobits.com/hc/zh-tw/articles/360018145852-KoKo-%E7%A9%8D%E5%88%86%E7%B0%A1%E4%BB%8B',
  zh_CN:
    'https://support.koobits.com/hc/zh-tw/articles/360018145852-KoKo-%E7%A9%8D%E5%88%86%E7%B0%A1%E4%BB%8B',
  ba: 'https://support.koobits.com/hc/id-id/articles/360018145852-Apa-itu-KoKo-Credits',
};
