import React from 'react';
import useSound from 'use-sound';

import HintIcon from './icon-hint.svg';

import hintSound from './hint.mp3';

import { StyledContainer, IconContainer } from './Hint.styles';

const Hint = ({
  children,
  onClick,
  disabled,
  withHintSound = false,
  className,
}) => {
  const [play] = useSound(hintSound, {
    interrupt: false,
  });

  return (
    <StyledContainer>
      <IconContainer
        className={className}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          if (withHintSound) {
            play();
          }
          onClick();
        }}
      >
        <img src={HintIcon} alt="" />
      </IconContainer>
      {children}
    </StyledContainer>
  );
};

export default Hint;
