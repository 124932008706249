export const SUPPORT_URL = {
  en: 'https://support.koobits.com/hc/en-gb/articles/211244503-How-many-points-can-I-earn-in-Peer-Challenge-',
  zh_HK:
    'https://support.koobits.com/hc/zh-tw/articles/211244503-%E6%88%91%E5%8F%AF%E6%96%BC%E5%AD%B8%E9%9C%B8%E5%B0%8D%E6%88%B0%E4%B8%AD%E7%8D%B2%E5%BE%97%E5%A4%9A%E5%B0%91%E7%A9%8D%E5%88%86',
  zh_TW:
    'https://support.koobits.com/hc/zh-tw/articles/211244503-%E6%88%91%E5%8F%AF%E6%96%BC%E5%AD%B8%E9%9C%B8%E5%B0%8D%E6%88%B0%E4%B8%AD%E7%8D%B2%E5%BE%97%E5%A4%9A%E5%B0%91%E7%A9%8D%E5%88%86',
  zh_CN:
    'https://support.koobits.com/hc/zh-tw/articles/211244503-%E6%88%91%E5%8F%AF%E6%96%BC%E5%AD%B8%E9%9C%B8%E5%B0%8D%E6%88%B0%E4%B8%AD%E7%8D%B2%E5%BE%97%E5%A4%9A%E5%B0%91%E7%A9%8D%E5%88%86',
  ba: 'https://support.koobits.com/hc/id-id/articles/211244503-Berapa-banyak-poin-yang-bisa-didapatkan-dari-Peer-Challenge',
};
