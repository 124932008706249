import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: absolute;
  width: 80%;
  bottom: ${({ viewWidth }) => (viewWidth ? '7%' : '10%')};
  left: 50%;
  transform: translateX(-50%);
  padding: ${({ allLength }) =>
    allLength > 20 ? '0' : allLength > 8 ? '1rem 0' : '1rem 0'};
  font-size: 3rem;
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  font-weight: bold;
  display: flex;
  gap: 2%;
  align-items: flex-end;
  justify-content: center;
  line-height: 1;
  border-radius: 1rem;
  > span {
    width: auto;
    font-size: ${({ ch, inModal }) =>
      `clamp(${inModal ? '2rem' : '1rem'}, ${Math.max(
        3 - ch * 0.12,
        1
      )}rem, 3rem)`};
    text-align: center;
    display: inline-block;
    word-break: break-word;
    overflow-wrap: anywhere;
    white-space: normal;
    @media screen and (max-width: 768px) {
      font-size: ${({ ch }) =>
        `clamp(1rem, ${Math.max(2.5 - ch * 0.15, 1)}rem, 2.5rem)`};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    bottom: 8%;
    width: 86%;
    padding: ${({ ch }) => (ch > 10 ? '1rem 0.5rem 0.2rem' : '1rem 0.5rem')};
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.mobile}px) {
    border-radius: 0.5rem;
  }
`;
