import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import {
  AddStepsIcon,
  BarModelIcon,
  FourOpsIcon,
} from 'assets/img/assist-tools';
import VideoIcon from 'assets/img/icon-play-video.svg';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { sendEventTracking } from 'helpers/UserEventTracking';
import { QuestionFeedbackLink, VideoPlayerModal } from 'components/Shared';
import { Container } from './MissionNoteBookBottom.styles';

const MissionNoteBookBottom = ({
  data,
  answer,
  subject,
  curriculumId,
  qnID,
  skillID,
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation(['selfPracticeQnView']);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const helperIcon = [AddStepsIcon, BarModelIcon, FourOpsIcon];

  return (
    <>
      <VideoPlayerModal
        isOpen={showVideoModal}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
          enablejsapi: 1,
          origin: window.location.origin,
        }}
        videoId={data && data.SIO.videoCode}
        onClose={() => setShowVideoModal(false)}
        errorTracking={(ecode) => {
          sendEventTracking('mission', 'video_error', {
            sub: subject,
            ci: curriculumId,
            vi: data.SIO.videoCode,
            ecode,
          });
        }}
        openNewTabTracking={(ecode) => {
          sendEventTracking('mission', 'video_error_click', {
            sub: subject,
            ci: curriculumId,
            vi: data.SIO.videoCode,
            ecode,
          });
        }}
      />
      <Container answer={answer} isHide={isMobile && answer === 'wrong'}>
        {answer === 'unknown' && (
          <div className="qn-instruction">
            {data &&
              isNil(data.SIO.videoCode) !== true &&
              data.SIO.videoCode !== '' && (
                <button
                  type="button"
                  className="video-btn"
                  onClick={() => {
                    setShowVideoModal(true);
                    // EVENT TRACKING: video view
                    sendEventTracking('mission', 'video_view', {
                      sub: subject,
                      ci: curriculumId,
                      vi: data.SIO.videoCode,
                    });
                  }}
                >
                  <span>
                    <img src={VideoIcon} alt="play" />
                  </span>
                  {t('selfPracticeQnView:content.video', 'Video Tutorial')}
                </button>
              )}
            <p className="use-helper-text">
              {t(
                'selfPracticeQnView:content.youCanClick',
                'You can click on the'
              )}
              <span>
                {helperIcon.map((icon, i) => (
                  <img src={icon} alt="helper" key={`icon-${i}`} />
                ))}
              </span>
              <br />
              {t(
                'selfPracticeQnView:content.onRightMenu',
                'on the right menu to add working.'
              )}
            </p>
          </div>
        )}
        <div className="hide-mobile">
          <QuestionFeedbackLink qnID={qnID} skillID={skillID} />
        </div>
      </Container>
    </>
  );
};

export default MissionNoteBookBottom;
