import React from 'react';

import { ProgressBarLine, ProgressBarContent } from './QnProgressBar.styles';

const QnProgressBar = ({ progress, totalQn }) => {
  return (
    <ProgressBarLine>
      <ProgressBarContent width={(progress / totalQn) * 100}>
        <div className="white-line" />
      </ProgressBarContent>
    </ProgressBarLine>
  );
};

export default QnProgressBar;
