import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';
import moment from 'moment-timezone';

import { getChampionClassMonth } from 'store/championClass/championClassSlice';
import formatI18nDateTime from 'helpers/formatI18nDateTime';
import BackButton from 'components/Shared/BackButton';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { ChallengeType } from 'pages/Leaderboard/v2/components';
import LeftKid from 'assets/img/leaderboard/champion-class/Kids1.svg';
import RightKid from 'assets/img/leaderboard/champion-class/Kid2.svg';
import CCToggle from '../CCToggle';
import ClassAchievements from './ClassAchievements';

import {
  Container,
  CenterBar,
  BrainHeading,
  ButtonContainer,
} from './HeaderMathClassicCC.styles';

const LOADING = 'Loading...';

const HeaderMathClassicCC = ({
  activeCategory,
  currentPeriod,
  setCurrentPeriod,
  singaporeSchool,
  toggleHandler,
}) => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, isLoading } = useSelector((state) => state.leaderboard.months);
  const { type, championClass } = useSelector((state) => state.championClassV2);
  const currentClass = championClass?.data?.[0];

  useEffect(() => {
    dispatch(getChampionClassMonth());
    // eslint-disable-next-line
  }, []);

  // get 12 months data
  const monthsData = data?.slice(0, 12);
  const showMonthYear = (item) => {
    return moment(`${item?.year}-${item?.name}`).format(
      formatI18nDateTime({
        format: 'YYYY-MMM',
        yearDate: true,
        language: i18n.language,
      })
    );
  };

  // get year & month from selected dropdown item
  const dropdownValue = (value) => {
    setCurrentPeriod(value);
  };

  return (
    <Container>
      <ButtonContainer>
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(209, 194, 20, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/leaderboard?subject=math')}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <img height="100%" src={LeftKid} alt="left-kid" className="left-kid" />
      <CenterBar activeTerm={type}>
        <div className="middle-content">
          <BrainHeading
            content={t(
              'leaderboard:championClass.championClass',
              `Champion Class`
            )}
          >
            {t('leaderboard:championClass.championClass', `Champion Class`)}
          </BrainHeading>
          {singaporeSchool && <CCToggle clickHandler={toggleHandler} />}
        </div>
        {activeCategory === 4 && isNil(championClass.error) && (
          <ClassAchievements
            name={currentClass?.className}
            currentCP={currentClass?.cpPoints}
          />
        )}
        <div className="curriculum-date">
          <DropdownMenu
            dataCy="dropdown-month"
            selectedValue={isLoading ? LOADING : showMonthYear(currentPeriod)}
            valueKey={null}
            values={monthsData}
            setValue={dropdownValue}
            valueTemplate={showMonthYear}
            width="150px"
            className="drop-down"
            indexKey
          />
        </div>
        <img
          className="right-kid"
          src={RightKid}
          alt="right-kid"
          style={{ bottom: '0', right: '-10%' }}
        />
      </CenterBar>
      <ChallengeType styles={{ top: '12%' }} />
    </Container>
  );
};

export default HeaderMathClassicCC;
