import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
`;

export const IconContainer = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media (max-width: 1023px) {
    width: 45.21px;
    height: 45.21px;
  }
`;
