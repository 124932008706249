import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { isNil, isEmpty } from 'ramda';
import moment from 'moment-timezone';
import Lottie from 'react-lottie';
import { useMediaQuery } from 'react-responsive';

import {
  ANSWER_TYPE,
  WOD_GAME_TYPE,
  WOD_STATUS,
  WOD_ERROR_CODE,
  WORD_CREATION_STATUS,
} from '../constant';

import {
  updateHint,
  getWordsList,
  submitGameAnswer,
  getWodGameStatus,
  getRetryWordsList,
  getWordCreationStatus,
} from 'store/wod/wodSlice';

import { getRewards } from 'store/dashboard/rewardsSlice';

import useIsBelowTablet from 'hooks/responsive/useIsBelowTablet';
import useDCcorrectSound from 'hooks/useDCcorrectSound';
import useWrongSound from 'hooks/useWrongSound';
import { useLoading } from 'hooks/useLoading';

import { Spinner } from 'react-bootstrap';

import { Button, ErrorModal, BackButton } from 'components/Shared';
import { Modifier } from './components';
import {
  Hint,
  Voice,
  MeaningAndTranslation,
  MeaningAndTranslateModal,
  QnProgressBar,
  ClaimKokoCredit,
  Loading,
} from '../components';

import { ReactComponent as BackIcon } from 'assets/img/icon-back.svg';
import { ReactComponent as IconWrong } from './imgs/wrongIcon.svg';
import animationData from 'assets/animation/lf30_editor_xakgefzo.json';

import {
  Container,
  Left,
  Right,
  StyledBackButton,
  QnContainer,
  ActionContainer,
  StyledProgress,
  TopBar,
  AnswerContainer,
  StyledLoadingMessage,
  Card,
  StyledDashContainer,
} from './KooCards2.styles';

const KooCards = () => {
  const { t } = useTranslation(['wod', 'common']);
  const { subject } = useParams();
  const isBelowTablet = useIsBelowTablet();
  const history = useHistory();
  const dispatch = useDispatch();
  const intervalRef = useRef(null);

  const isTablet = useMediaQuery({
    query: `(max-width: 910px)`,
  });

  const { play: playCorrectSound } = useDCcorrectSound({ hasSound: true });
  const { play: playWrongSound } = useWrongSound();

  const { userID, timezone } = useSelector((state) => state.login);
  const { wordsList, hint, submitResult, wodGameStatus, gameResult } =
    useSelector((state) => state.wod);

  const [inputValue, setInputValue] = useState('');
  const [order, setOrder] = useState(1);
  const [correctCount, setCorrectCount] = useState(0);
  const [hintText, setHintText] = useState('');
  const [currentAnswerType, setCurrentAnswerType] = useState(
    ANSWER_TYPE.DEFAULT
  );
  const [displayContent, setDisplayContent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showKokoModal, setShowKokoModal] = useState(false);
  const [isHintActive, setIsHintActive] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);

  const kooCardDatum =
    wordsList?.koocardsList?.find((word) =>
      isFirstLoad ? !word.result : word.wordOrder === order
    ) ?? null;

  const allLoading =
    wordsList.isLoading ||
    wodGameStatus.isLoading ||
    gameResult.isLoading ||
    checkingStatus;
  const { progress } = useLoading(allLoading);
  const isLoading = progress !== 100;
  const percentage =
    (1 / (kooCardDatum?.wordText.length - 1)) * (hintText.length - 1);
  const koocardsStatus = wodGameStatus?.data?.find(
    (data) => data.gameType === WOD_GAME_TYPE.KOOCARDS
  );

  const getCreationStatus = async () => {
    setCheckingStatus(true);
    try {
      const creationStatusAction = await dispatch(getWordCreationStatus());
      const statusResult = unwrapResult(creationStatusAction);
      if (statusResult?.processStatusId === WORD_CREATION_STATUS.COMPLETED) {
        clearInterval(intervalRef.current);
        await dispatch(
          getWordsList({
            gameType: WOD_GAME_TYPE.KOOCARDS,
            inquiryDate: moment.tz(timezone).format('yyyy-MM-DD'),
            status: koocardsStatus?.status,
          })
        );
        setCheckingStatus(false);
        return;
      }
      if (statusResult?.processStatusId === WORD_CREATION_STATUS.FAILED) {
        clearInterval(intervalRef.current);
        setShowErrorModal(true);
        setCheckingStatus(false);
      }
    } catch (error) {
      console.log(error);
      setShowErrorModal(true);
    }
  };

  const handleGetKooCards = useCallback(async () => {
    if (koocardsStatus?.status === WOD_STATUS.COMPLETED) {
      dispatch(getRetryWordsList({ gameType: WOD_GAME_TYPE.KOOCARDS }));
      setCheckingStatus(false);
    } else {
      try {
        const act = await dispatch(
          getWordsList({
            gameType: WOD_GAME_TYPE.KOOCARDS,
            inquiryDate: moment.tz(timezone).format('yyyy-MM-DD'),
            status: koocardsStatus?.status,
          })
        );
        unwrapResult(act);
        setCheckingStatus(false);
      } catch (error) {
        console.log('error', error);
        if (error.message === WOD_ERROR_CODE.NO_WOD_FOUND) {
          getCreationStatus();
          intervalRef.current = setInterval(getCreationStatus, 2000);
        } else {
          setShowErrorModal(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, koocardsStatus]);

  useEffect(() => {
    if (!isNil(userID) && !isNil(wodGameStatus.data)) {
      handleGetKooCards();
    }
    // eslint-disable-next-line
  }, [handleGetKooCards, userID, koocardsStatus]);

  useEffect(() => {
    if (!isNil(userID) && isNil(wodGameStatus.data)) {
      dispatch(getWodGameStatus());
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  useEffect(() => {
    if (kooCardDatum) {
      const hintIndex = kooCardDatum.wordHintIdx
        ? kooCardDatum.wordHintIdx + 1
        : 1;
      setHintText(() => kooCardDatum.wordText.slice(0, hintIndex));
      setOrder(kooCardDatum.wordOrder);
      setCorrectCount(kooCardDatum.wordOrder - 1);
    }
  }, [kooCardDatum]);

  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch, subject]);

  const renderButtonText = useCallback(() => {
    switch (currentAnswerType) {
      case ANSWER_TYPE.CORRECT: {
        return (
          <div className="next-button">
            {t('common:next', 'Next')}
            <BackIcon />
          </div>
        );
      }
      case ANSWER_TYPE.WRONG: {
        return t('common:tryAgain', 'Try Again');
      }
      default: {
        return t('common:check', 'Check');
      }
    }
  }, [currentAnswerType, t]);

  const handleButtonClick = async () => {
    switch (currentAnswerType) {
      case ANSWER_TYPE.CORRECT: {
        if (order < wordsList?.koocardsList?.length) {
          setCurrentAnswerType(ANSWER_TYPE.DEFAULT);
          setOrder((prev) => prev + 1);
          setInputValue('');
          setHintText('');
        } else {
          history.push({
            pathname: `/words-of-the-day/${subject}/koocards/result`,
            state: {
              isGameFinish: true,
            },
          });
        }
        return;
      }
      case ANSWER_TYPE.WRONG: {
        setCurrentAnswerType(ANSWER_TYPE.DEFAULT);
        return;
      }
      default: {
        setIsFirstLoad(false);
        try {
          const submitAction = await dispatch(
            submitGameAnswer({
              gameWordId: kooCardDatum.gameWordId,
              answer: inputValue.trim(),
              result:
                inputValue?.trim()?.toLowerCase() ===
                kooCardDatum.wordText?.trim()?.toLowerCase(),
            })
          );
          const res = unwrapResult(submitAction);
          if (res.gameResult) {
            playCorrectSound();
            setCurrentAnswerType(ANSWER_TYPE.CORRECT);
            if (res.koko > 0) setShowKokoModal(true);
            if (correctCount !== wordsList?.koocardsList?.length)
              setCorrectCount((prev) => prev + 1);
          } else {
            playWrongSound();
            setCurrentAnswerType(ANSWER_TYPE.WRONG);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleHintClick = async () => {
    setIsHintActive(true);
    try {
      const action = await dispatch(updateHint(kooCardDatum.gameWordId));
      const result = unwrapResult(action);
      const text = kooCardDatum.wordText;
      if (result) {
        setHintText((prev) => text.slice(0, prev.length + 1));
      }
      setTimeout(() => {
        setIsHintActive(false);
      }, 1000);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpen = (contentButton) => {
    setDisplayContent(contentButton);
    if (!openModal) {
      setOpenModal(true);
    }
  };
  const handleClose = () => {
    setDisplayContent(null);
    setOpenModal(false);
  };

  return (
    <Container>
      {showErrorModal && (
        <ErrorModal
          errorMessage="An error occurred while processing your request. Please contact our support."
          backText="Back"
          closeHandler={() => history.push(`/words-of-the-day/${subject}`)}
        />
      )}
      <Loading
        progress={progress}
        fadeOutAnimation={progress === 100}
        message={
          <StyledLoadingMessage>
            <Trans i18nKey="wod:kooCards.loading">
              <span className="miko">Miko</span> is{' '}
              <span>personalising 10 words</span> for you!
            </Trans>
          </StyledLoadingMessage>
        }
      />
      <ClaimKokoCredit
        showModal={showKokoModal}
        resultUrl={`/words-of-the-day/${subject}/koocards/result`}
        game="KooCards"
        setShowModal={setShowKokoModal}
      />
      {isTablet && (
        <TopBar>
          {isTablet && (
            <BackButton
              btnBgColor="#7b7b7b"
              backText="Back"
              className="back-btn"
              onClick={() => history.push(`/words-of-the-day/${subject}`)}
            />
          )}
          <div className="question-progress">
            <span>{order}</span>/{wordsList?.koocardsList?.length}
          </div>
          <QnProgressBar
            progress={correctCount}
            totalQn={wordsList?.koocardsList.length}
          />
        </TopBar>
      )}
      {!isNil(kooCardDatum) && !allLoading && (
        <>
          <Left isLoading={allLoading}>
            <StyledBackButton
              backText={t('common:back', 'Back')}
              btnBgColor="#404447"
              padding="0.3rem 1.2rem"
              onClick={() => history.push(`/words-of-the-day/${subject}`)}
            />
            <QnContainer>
              <Card
                url={kooCardDatum?.imageUrl}
                ch={
                  kooCardDatum?.wordText?.length +
                  kooCardDatum?.prefix?.length +
                  kooCardDatum?.suffix?.length
                }
                hintLength={hintText.length}
              >
                <p className="text">
                  {kooCardDatum?.prefix}
                  <span className="answer">
                    {hintText}{' '}
                    <StyledDashContainer
                      remainingCount={
                        kooCardDatum?.wordText?.length - hintText?.length
                      }
                      allLength={kooCardDatum?.wordText?.length}
                    >
                      {new Array(
                        kooCardDatum?.wordText?.length - hintText?.length
                      )
                        .fill('_')
                        .map((_, index) => (
                          <div className="dash" key={index} />
                        ))}
                    </StyledDashContainer>
                  </span>{' '}
                  {kooCardDatum?.suffix}
                </p>
              </Card>
              <Modifier className="legend" />
              <ActionContainer>
                <Hint
                  disabled={
                    percentage === 1 ||
                    hint.isLoading ||
                    currentAnswerType === ANSWER_TYPE.CORRECT ||
                    isHintActive
                  }
                  onClick={handleHintClick}
                  withHintSound={true}
                  className="koocards-action"
                >
                  <StyledProgress now={100 - Math.floor(percentage * 100)} />
                </Hint>
                <Voice
                  audioLink={kooCardDatum.voiceOverUrl}
                  className="koocards-action"
                />
                {currentAnswerType === ANSWER_TYPE.CORRECT && (
                  <>
                    <MeaningAndTranslation
                      handleOpen={handleOpen}
                      displayContent={displayContent}
                    />
                    <MeaningAndTranslateModal
                      openModal={openModal}
                      type={displayContent}
                      closeHandler={handleClose}
                      wordEntryId={kooCardDatum.wordEntryId}
                      imgUrl={kooCardDatum.imageUrl}
                      word={kooCardDatum.wordText}
                      wordClass={kooCardDatum.wordClass}
                    />
                  </>
                )}
              </ActionContainer>
            </QnContainer>
          </Left>
          <Right isLoading={isLoading}>
            {!isTablet && (
              <TopBar>
                <div className="question-progress">
                  <span>{order}</span>/{wordsList?.koocardsList?.length}
                </div>
                <QnProgressBar
                  progress={correctCount}
                  totalQn={wordsList?.koocardsList.length}
                />
              </TopBar>
            )}
            <AnswerContainer answerType={currentAnswerType}>
              <div className="answer-wrapper">
                <form
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleButtonClick();
                  }}
                  className="form-wrap"
                >
                  <div className="input-container">
                    <input
                      value={inputValue}
                      placeholder={kooCardDatum.wordText[0]}
                      disabled={currentAnswerType !== ANSWER_TYPE.DEFAULT}
                      type="text"
                      autoComplete="off"
                      onChange={(e) => handleInputChange(e.target.value)}
                      autoCapitalize="off"
                      autoCorrect="off"
                      spellCheck="false"
                    />
                    {currentAnswerType === ANSWER_TYPE.WRONG && <IconWrong />}
                  </div>
                  <Button
                    margin={isBelowTablet ? '1rem auto 0.75rem' : '2rem auto 0'}
                    variant="primary"
                    width="80%"
                    isDisabled={
                      submitResult.isLoading ||
                      isHintActive ||
                      isEmpty(inputValue)
                    }
                    disableColor="#ff7121"
                    style={{
                      zIndex: 2,
                      position: 'relative',
                      opacity:
                        submitResult.isLoading ||
                        isHintActive ||
                        isEmpty(inputValue)
                          ? 0.3
                          : 1,
                    }}
                    fontSize="24px"
                    type="submit"
                  >
                    {renderButtonText()}{' '}
                    {submitResult.isLoading && (
                      <Spinner
                        animation="border"
                        size="sm"
                        style={{
                          borderWidth: '0.2rem',
                          verticalAlign: 'baseline',
                        }}
                      />
                    )}
                  </Button>
                </form>
                {currentAnswerType === ANSWER_TYPE.CORRECT && (
                  <Lottie
                    isClickToPauseDisabled
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    isStopped={false}
                    isPaused={false}
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      zIndex: 1,
                    }}
                  />
                )}
              </div>
            </AnswerContainer>
          </Right>
        </>
      )}
    </Container>
  );
};

export default KooCards;
