import styled from 'styled-components';
export const Selector = styled.div`
  position: relative;
  width: fit-content;
`;

export const SelectButton = styled.button`
  padding: 12px 60px 8px 40px;
  border-radius: 7px;
  background-color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  min-width: 100px;
  position: relative;

  &:hover {
    background-color: #f8f8f8;
  }
  svg {
    position: absolute;
    right: 14px;
    top: 49%;
    transform: translateY(-50%);
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
`;

export const MenuItem = styled.button`
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  &:first-child {
    padding-top: 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
