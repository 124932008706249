import styled from 'styled-components';
import { Deco, KokoCoins, ImgCongratulations } from './imgs';

export const Container = styled.div`
  background: #0090e2;
  border-radius: 25px;
  min-width: 618px;
  box-shadow: 0px 8px 0px #005c8d;
  color: #ffffff;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0;
  }
  .heavy-bold {
    font-family: 'Linotte-Heavy';
  }
  @media (max-width: 635px) {
    min-width: 95vw;
  }
`;

export const Top = styled.div`
  background: ${({ isOpen }) =>
    !isOpen ? `url(${Deco}) no-repeat 3rem center, #009aeb` : '#009aeb'};
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1.2rem;

  .yellow {
    color: #ffc938;
  }

  .title {
    font-size: 20px;
    margin-top: ${({ isOpen }) => (isOpen ? '-2rem' : '-1rem')};
    font-weight: ${({ isOpen }) => (isOpen ? 600 : 700)};
  }
  .sub-title {
    font-weight: 600;
  }

  &::before {
    content: ${({ isOpen }) =>
      isOpen ? `url(${ImgCongratulations})` : `url(${KokoCoins})`};
    position: relative;
    top: ${({ isOpen }) => (isOpen ? '-2.5rem' : '-1.5rem')};
  }

  @media (max-width: 600px) {
    background: #009aeb;
    .title {
      font-size: 18px;
      text-align: center;
      margin-top: -2rem;
    }
    .sub-title {
      text-align: center;
    }
    &::before {
      content: ${({ isOpen }) =>
        isOpen ? `url(${ImgCongratulations})` : `url(${KokoCoins})`};
      position: relative;
      top: -2.5rem;
    }
  }
  @media (max-width: 365px) {
    .title {
      font-size: 16px;
    }
    .sub-title {
      font-size: 12px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 2rem ${({ isOpen }) => (isOpen ? '2rem' : '3rem')};
  gap: 2.5rem;
  box-sizing: border-box;
  justify-content: center;
  @media (max-width: 581px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 515px) {
    gap: 1.2rem;
  }
  @media (max-width: 456px) {
    gap: 2rem;
  }
  @media (max-width: 390px) {
    gap: 1rem;
  }
`;

export const Gift = styled.div`
  flex: 0 0 92px;
  display: flex;
  flex-direction: column;
  max-width: 92px;
  height: 92px;
  background: #1fa3f5;
  border-radius: 100%;
  align-items: ${({ isOpen }) => (isOpen ? 'center' : 'flex-end')};
  justify-content: flex-end;
  cursor: pointer;
  .box {
    margin-bottom: -2px;
  }
  .points {
    font-size: 26px;
    margin-bottom: -0.8rem;
    margin-top: -0.5rem;
    span {
      font-family: 'Linotte';
      font-size: 15px;
      font-weight: 700;
    }
  }
  @media (max-width: 456px) {
    max-width: 66px;
    height: 66px;
    .box {
      width: 80%;
    }
    .coins {
      width: 95%;
    }
    .points {
      font-size: 20px;
      span {
        font-size: 11px;
      }
    }
  }
`;

export const SpinningLight = styled.img`
  z-index: -1;
  position: absolute;
  top: -13rem;
  right: -7rem;
  -webkit-animation: fade 2s linear infinite;
  -moz-animation: fade 2s linear infinite;
  animation: fade 2s linear infinite;
  opacity: 0.2;

  @keyframes fade {
    0% {
      opacity: 0.2;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.2;
    }
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
