import React from 'react';
import useSound from 'use-sound';
import StaticSpeaker from 'assets/img/speaker-static.svg';
import Speaker from 'assets/img/speaker.gif';

import { StyledContainer } from './Voice.styles';

const Voice = ({
  disabled,
  audioLink,
  className,
  soundPlaying,
  isTutorial,
  inModal,
}) => {
  const [play, { isPlaying, stop }] = useSound(audioLink, {
    interrupt: false,
  });
  const playingSound = isPlaying || soundPlaying;

  const handleClick = () => {
    if (isPlaying) {
      stop();
    }
    if (!isTutorial) {
      play();
    }
  };

  return (
    <StyledContainer
      disabled={disabled}
      className={className}
      onClick={handleClick}
      inModal={inModal}
    >
      <img src={playingSound ? Speaker : StaticSpeaker} alt="" />
    </StyledContainer>
  );
};

export default Voice;
