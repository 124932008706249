import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  right: ${({ isChinese }) => (isChinese ? 30 : 38)}%;
  left: 34%;
  background: #fff;
  border-radius: 28px;
  border: #fff 14px solid;
  text-align: center;
  font-size: x-large;
  color: #fff;
  font-size: xx-large;
`;

export const InsideWrap = styled.div`
  border-radius: 20px;
  border-color: #03b8ff;
  border: solid 0px #03b8ff;
  background: #03b8ff;
  padding: 10px;
`;

export const TextInfo = styled.p`
  font-stretch: expanded !important;
`;

export const OpenTime = styled.b`
  color: #e3f81f;
  font-stretch: semi-expanded;
  font-weight: bolder;
  text-decoration: underline;
`;

export const CloseTime = styled.b`
  color: #742fdb;
  font-stretch: semi-expanded;
  font-weight: bolder;
  text-decoration: underline;
`;
