import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProfileSummary, ChangePassword } from './components';
import { Container } from './MyProfile.styles';

const MyProfile = () => {
  return (
    <Container>
      <Switch>
        <Route
          exact
          path="/profile-settings/profile"
          component={ProfileSummary}
        />
        <Route
          exact
          path="/profile-settings/profile/change-password"
          component={ChangePassword}
        />
      </Switch>
    </Container>
  );
};

export default MyProfile;
