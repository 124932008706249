import styled from 'styled-components';
import { Shadow, MikoJump } from './imgs';

import { Grid, OrangeDecoLeft, OrangeDecoRight } from '../SpellingBits/imgs';

export const Container = styled.div`
  background: url(${OrangeDecoLeft}) no-repeat left calc(100% - 3rem),
    url(${OrangeDecoRight}) no-repeat right calc(100% - 2rem),
    url(${Grid}) repeat, #ffc14b;
  background-blend-mode: normal, normal, normal, normal;
  -webkit-background-blend-mode: normal, normal, overlay, normal;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    background: url(${Grid}) repeat, #ffc14b;
    background-blend-mode: normal, normal;
  }
`;

export const ResultList = styled.div`
  min-width: 555px;
  position: relative;
  &::before {
    content: '';
    display: block;
    background: url(${MikoJump}) no-repeat left bottom;
    position: absolute;
    width: 300px;
    height: 375px;
    z-index: 3;
    background-size: contain;
    left: -19rem;
    bottom: 0;
  }
  &::after {
    content: '';
    display: block;
    background: url(${Shadow}) no-repeat center bottom;
    width: 100%;
    height: 35px;
    position: absolute;
    z-index: 0;
    bottom: -25px;
  }
  @media (max-width: 1150px) {
    &::before {
      width: 200px;
      left: -12rem;
    }
  }
  @media (max-width: 910px) {
    &::before {
      display: none;
    }
  }
  @media (max-width: 768px) {
    min-width: 0;
    width: 85%;
  }
`;

export const Header = styled.div`
  background: #f06c30;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #ff8c58;
  text-align: center;
  padding: 1rem;
  font-family: 'LuckiestGuy';
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  &::before,
  ::after {
    content: '';
    width: 12.5px;
    height: 12.5px;
    border-radius: 100%;
    background: #ff8c58;
    display: block;
  }
`;

export const ListContainer = styled.div`
  background: #ffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  position: relative;
  z-index: 1;
  height: 623px;
`;

export const WordItem = styled.div`
  background: rgba(236, 244, 243, 0.4);
  border-radius: 20px;
  font-size: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.1rem 1.5rem;
  font-weight: 600;
  span {
    font-size: 20px;
    color: rgba(152, 152, 152, 0.55);
  }
  p {
    margin-bottom: 0;
  }
`;

export const ActionBtns = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  @media (max-width: 455px) {
    gap: 0.5rem;
    .result-btn {
      width: 100%;
    }
  }
`;
