import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { BackButton } from 'components/Shared';

import { Container, RibbonContainer } from './ResultTopBar.styles';

const ResultTopBar = ({ game, backHandler }) => {
  const { t } = useTranslation(['common', 'wod']);
  const isMobile = useMediaQuery({
    query: `(max-width: 630px)`,
  });

  const translationKey = game === 'KooCards' ? 'kooCards' : 'spellingBits';

  return (
    <Container>
      <BackButton
        btnBgColor="#7b7b7b"
        backText="Back"
        className="back-btn"
        onClick={backHandler}
      />
      <RibbonContainer>
        {t('common:wellDone', 'Well done!')}{' '}
        {!isMobile &&
          t(`wod:${translationKey}.result`, `You have completed ${game}.`)}
      </RibbonContainer>
    </Container>
  );
};

export default ResultTopBar;
