import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  padding: ${({ inModal }) => inModal && '0 0 0 2rem'};
  @media (max-width: 768px) {
    padding: ${({ inModal }) => inModal && '0'};
  }
`;
export const StyledContent = styled.div`
  display: flex;
  padding: ${({ inModal }) => (inModal ? '1.5rem 0 1.5rem' : '1.5rem 2rem')};
  gap: 2rem;
  width: 100%;
  .card-container {
    width: 420px;
  }
  @media (max-width: 1024px) {
    flex-direction: ${({ inModal }) => inModal && 'column'};
    .card-container {
      width: 350px;
      display: ${({ inModal }) => inModal && 'flex'};
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: ${({ inModal }) => !inModal && '90vw'};
    padding: ${({ inModal }) => (inModal ? '1.5rem 0 1.5rem' : '0')};
    gap: 1rem;
    .card-container {
      width: unset;
      border-bottom: 1px solid #dbdbdb;
      padding: 1.5rem 2rem;
    }
  }
`;
export const StyledMeaning = styled.div`
  height: 560px;
  overflow-y: auto;
  flex: 1;
  .title {
    font-family: 'Linotte';
    font-size: 36px;
    font-weight: bold;
    padding-left: 1rem;
  }
  .modifier {
    color: #9f9f9f;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
  }
  ol {
    font-family: 'Linotte';
    font-size: 18px;
    padding-left: 2rem;
    padding-right: 1rem;
    li {
      padding-left: 10px;
      margin-bottom: 1.5rem;
      line-height: 1;
    }
  }
  @media (max-width: 768px) {
    padding: 0 2rem 1.5rem;
  }
`;
export const StyledTranslate = styled.div`
  padding: 1.2rem 0;
  width: 100%;
  height: 560px;
  flex: 0.95;

  .translate-card {
    background: rgba(255, 224, 129, 0.4);
    border-radius: 12px;
  }
  .translate-selector {
    background: rgba(255, 224, 129, 0.4);
    padding: 5px 8px;
    display: flex;
    justify-content: flex-end;
    border-radius: 12px 12px 0 0;
  }
  .english-button {
    padding: 12px 60px;
    border-radius: 7px;
    background: rgba(255, 255, 255, 0.4);
    display: inline-block;
    line-height: 1;
  }
  .translate-content {
    padding: 2rem 1rem 60px 2rem;
    background-color: #fff8e3;
    border-radius: 0 0 12px 12px;
    position: relative;
  }
  .translate-title {
    font-size: 2rem;
    font-family: 'Linotte';
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0.5rem;
  }
  .translate-modifier {
    color: #9f9f9f;
  }
  .translate-icon {
    padding: 1rem;
  }
  .translate-bottom {
    margin-top: 1rem;
  }
  @media (max-width: 1024px) {
    padding-top: ${({ inModal }) => inModal && '0'};
    .english-card,
    .translate-icon {
      display: ${({ inModal }) => inModal && 'none'};
    }
  }
  @media (max-width: 768px) {
    padding: 1.5rem 2rem;
  }
`;
export const StyledInModalActions = styled.div`
  background: #f4f4f4;
  width: 90px;
  border-radius: 0 20px 20px 0;
  padding: 50px 1rem 0 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .voice-icon {
    margin-bottom: 1rem;
  }
  @media (max-width: 768px) {
    background: transparent;
    padding: 0;
  }
`;
