import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Spinner as RoundSpinner } from 'react-bootstrap';

import { getGameResult, getWodGameStatus } from 'store/wod/wodSlice';

import { WOD_GAME_TYPE } from '../constant';

import {
  ResultTopBar,
  VocabCard,
  MeaningAndTranslateModal,
} from '../components';
import { Button, Spinner, ConfettiWrapper } from 'components/Shared';

import {
  Container,
  Content,
  StyledProgress,
  ResultContainer,
  ActionBtns,
} from './KooCardsResult.styles';

const KooCardsResult = () => {
  const history = useHistory();
  const { t } = useTranslation(['common']);
  const { subject } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const showConfetti = state?.isGameFinish;
  const audioRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedWord, setSelectedWord] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { userID, timezone } = useSelector((state) => state.login);
  const { isLoading, koocardsResult } = useSelector(
    (state) => state.wod.gameResult
  );
  const { wodGameStatus, wordsList } = useSelector((state) => state.wod);

  const spellingBitsAttemptCount = wodGameStatus?.data?.find(
    (data) => data.gameType === WOD_GAME_TYPE.SPELLING_BITS
  )?.dailyAttempts;

  const goToCoverPage = () => history.push(`/words-of-the-day/${subject}`);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const tryAgainHandler = async () => {
    try {
      await dispatch(getWodGameStatus());
      history.push(`/words-of-the-day/${subject}/koocards`);
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const cardClickHandler = (card) => {
    setSelectedWord(card);
    setShowModal(true);
    // Stop and reset previous audio if playing
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.removeEventListener('ended', () => setIsPlaying(false));
    }
    const audio = new Audio(card.voiceOverUrl);
    audioRef.current = audio;
    audio.addEventListener('ended', () => setIsPlaying(false));

    audio
      .play()
      .then(() => setIsPlaying(true))
      .catch((e) => console.error('Playback error:', e));
  };

  useEffect(() => {
    if (!isNil(userID)) {
      dispatch(
        getGameResult({
          gameType: WOD_GAME_TYPE.KOOCARDS,
          inquiryDate: moment.tz(timezone).format('yyyy-MM-DD'),
        })
      );
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  useEffect(() => {
    if (!isNil(userID) && isNil(wodGameStatus.data)) {
      dispatch(getWodGameStatus());
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  return (
    <Container>
      {showConfetti && !isLoading && koocardsResult && <ConfettiWrapper />}
      <MeaningAndTranslateModal
        inModal
        openModal={showModal}
        closeHandler={handleCloseModal}
        type="meaning"
        imgUrl={selectedWord?.imgUrl}
        word={selectedWord?.wordText}
        wordEntryId={selectedWord?.wordEntryId}
        soundPlaying={isPlaying}
      />
      <ResultTopBar game="KooCards" backHandler={goToCoverPage} />
      <Content>
        {isLoading && <Spinner color="#ffffff" />}
        {!isLoading && koocardsResult && (
          <>
            <ResultContainer>
              {koocardsResult?.gameWords?.map((card) => (
                <div
                  key={card.gameWordId}
                  className="card-group"
                  onClick={() => cardClickHandler(card)}
                >
                  <VocabCard
                    url={card.imgUrl}
                    textLength={card?.wordText?.length}
                    textDisplay={`${card.prefix} ${card.wordText} ${card.suffix}`}
                    hintText={card?.wordText}
                    viewWidth={2}
                    animatedOnHover
                  />
                  <StyledProgress
                    now={
                      ((card.wordText.length - 1 - card.wordHintIdx) /
                        (card.wordText.length - 1)) *
                      100
                    }
                  />
                </div>
              ))}
            </ResultContainer>
            <ActionBtns showIcon={spellingBitsAttemptCount === 0}>
              <Button
                variant="transparent"
                fontSize="22px"
                padding="0.6rem 2.5rem"
                onClick={tryAgainHandler}
                isDisabled={wordsList?.isLoading}
                disableColor="#888888"
              >
                {t('common:playAgain', ' Play Again')}{' '}
                {wordsList?.isLoading && (
                  <RoundSpinner animation="border" size="sm" variant="light" />
                )}
              </Button>
              <Button
                variant="orange4"
                fontSize="22px"
                padding={
                  spellingBitsAttemptCount === 0
                    ? '0.6rem 5.5rem 0.6rem 2.5rem'
                    : '0.6rem 3rem 0.6rem 2.5rem'
                }
                style={{ position: 'relative' }}
                onClick={goToCoverPage}
              >
                {t('common:nextGame', 'Next Game')}
              </Button>
            </ActionBtns>
          </>
        )}
      </Content>
    </Container>
  );
};

export default KooCardsResult;
