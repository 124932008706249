import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import {
  StyledModal,
  StyledModalBody,
  StyledModalBodyInner,
  StyledModalMovieWrap,
  StyledCloseButton,
  StyledErrorContainer,
} from './VideoPlayerModal.style';

const VideoPlayerModal = ({
  isOpen,
  youtube,
  videoId,
  onClose,
  errorTracking,
  openNewTabTracking,
}) => {
  const [hasError, setHasError] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const playerRef = useRef(null);
  const { t } = useTranslation('common');

  const handleError = () => {
    if (errorTracking) {
      const videoElement = playerRef.current?.getInternalPlayer();
      if (videoElement.error) {
        errorTracking(videoElement.error.code);
        setErrorCode(videoElement.error.code);
      }
    }
    setHasError(true);
  };

  return (
    <StyledModal
      show={isOpen}
      keyboard={false}
      onHide={onClose}
      centered
      backdrop="static"
      size="lg"
    >
      <StyledModalBody>
        <StyledModalBodyInner>
          <StyledModalMovieWrap>
            {hasError && (
              <StyledErrorContainer>
                <a
                  href={`https://youtu.be/${videoId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (openNewTabTracking) {
                      openNewTabTracking(errorCode);
                    }
                  }}
                >
                  {t('common:video.openNewTab', 'Open in new tab')}
                </a>
              </StyledErrorContainer>
            )}
            <ReactPlayer
              ref={playerRef}
              url={`https://youtu.be/${videoId}`}
              controls
              loop={false}
              config={{
                youtube: {
                  playerVars: youtube,
                },
              }}
              onError={handleError}
              onReady={() => {
                const player = playerRef.current?.getInternalPlayer();
                if (player?.addEventListener) {
                  player.addEventListener('onError', (e) => {
                    if (errorTracking) {
                      errorTracking(e.data);
                      setErrorCode(e.data);
                    }
                    setHasError(true);
                  });
                }
              }}
            />
            <StyledCloseButton onClick={() => onClose()} />
          </StyledModalMovieWrap>
        </StyledModalBodyInner>
      </StyledModalBody>
    </StyledModal>
  );
};

export default VideoPlayerModal;
