import React from 'react';
import Tour from 'reactour';
import useIsBelowTablet from 'hooks/responsive/useIsBelowTablet';
import CustomTooltip from './CustomTooltip';

const Tutorial = ({ steps, callback, closeHandler }) => {
  const isBelowTablet = useIsBelowTablet();

  const cleanupSecondHighlight = () => {
    const svg = document.querySelector('#___reactour > div > svg');
    if (!svg) return;

    const mask = svg.querySelector('#mask-main');
    if (!mask) return;

    const secondHighlightElements = mask.querySelectorAll(
      '.second-highlight-mask'
    );
    secondHighlightElements.forEach((e) => {
      mask.removeChild(e);
    });
  };

  const handleStepChange = (index) => {
    if (callback) {
      callback(index);
    }
    cleanupSecondHighlight();
  };

  const reposition = (step, node) => {
    const tooltip = document.querySelector('.reactour__helper');

    if (step.reposition) {
      if (tooltip && node) {
        const rect = node.getBoundingClientRect();
        if (step.position && step.position !== 'bottom') {
          if (step.position === 'right') {
            tooltip.style.top = `${rect.top + rect.height / 2}px`;
            tooltip.style.left = `${rect.right + 120}px`;
            tooltip.style.transform = 'translate(0, -50%)';
          }
        } else {
          tooltip.style.top = `${rect.bottom + 10}px`;
          tooltip.style.left = `${rect.left + rect.width / 2}px`;
          tooltip.style.transform = 'translate(-50%, 0)';
        }
      }
    } else if (tooltip) {
      tooltip.style.top = '';
      tooltip.style.left = '';
      tooltip.style.transform = '';
    }
    if (isBelowTablet) {
      const rect = node?.getBoundingClientRect();
      if (rect) {
        tooltip.style.width = `86vw`;
        tooltip.style.top = `${rect.top + rect.height + 22}px`;
        tooltip.style.left = '54%';
        tooltip.style.transform = 'translate(-50%, 0)';
      }
    }
  };

  const newSteps = steps.map((step) => {
    if (step.secondTarget) {
      return {
        ...step,
        position: step.position || 'bottom',
        action: (node) => {
          const svg = document.querySelector('#___reactour > div > svg');
          if (!svg) return;

          const mask = svg.querySelector('#mask-main');
          if (!mask) return;

          const secondTargetElements = document.querySelectorAll(
            step.secondTarget
          );
          if (secondTargetElements.length > 0) {
            secondTargetElements.forEach((e) => {
              const rect = e.getBoundingClientRect();
              const newRect = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'rect'
              );
              const computedStyle = window.getComputedStyle(e);
              const borderRadius = computedStyle.borderRadius;
              newRect.setAttribute('x', rect.x);
              newRect.setAttribute('y', rect.y);
              newRect.setAttribute('width', rect.width);
              newRect.setAttribute('height', rect.height);
              newRect.setAttribute('fill', 'black');
              newRect.setAttribute('class', 'second-highlight-mask');
              if (borderRadius !== '0px') {
                const radiusValue = parseInt(borderRadius);
                newRect.setAttribute('rx', radiusValue);
                newRect.setAttribute('ry', radiusValue);
              }
              document.getElementById('clip-path').innerHTML = '';

              mask.appendChild(newRect);
            });
          }
          reposition(step, node);
        },
      };
    }
    return {
      ...step,
      position: step.position || 'bottom',
      action: (node) => {
        reposition(step, node);
      },
    };
  });

  return (
    <Tour
      steps={newSteps}
      isOpen={true}
      onRequestClose={() => {}}
      getCurrentStep={handleStepChange}
      CustomHelper={(props) => (
        <CustomTooltip
          {...props}
          steps={newSteps}
          onCloseHandler={closeHandler}
        />
      )}
      showNavigation={false}
      showButtons={true}
      showNumber={false}
      disableInteraction={true}
      styles={{
        maskWrapper: {
          color: 'rgba(0, 0, 0, 0.5)',
        },
        spotlight: {
          borderRadius: 'inherit',
        },
      }}
    />
  );
};

export default Tutorial;
