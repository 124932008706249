import React, { useState } from 'react';
import ReactKeyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { StyledKeyboardContainer } from './Keyboard.styles';

const Keyboard = ({ onChange, showKeyboard, inputRef }) => {
  const [isFirstChar, setIsFirstChar] = useState(true);

  const onKeyboardInput = (input) => {
    onChange(input);
    setIsFirstChar(input.length === 0);
    setTimeout(() => {
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    }, 0);
  };
  return (
    <StyledKeyboardContainer showKeyboard={showKeyboard}>
      <ReactKeyboard
        onChange={onKeyboardInput}
        layout={{
          default: [
            'q w e r t y u i o p',
            'a s d f g h j k l',
            'z x c v b n m {backspace}',
          ],
        }}
        display={{
          '{backspace}': '⌫',
          q: isFirstChar ? 'Q' : 'q',
          w: isFirstChar ? 'W' : 'w',
          e: isFirstChar ? 'E' : 'e',
          r: isFirstChar ? 'R' : 'r',
          t: isFirstChar ? 'T' : 't',
          y: isFirstChar ? 'Y' : 'y',
          u: isFirstChar ? 'U' : 'u',
          i: isFirstChar ? 'I' : 'i',
          o: isFirstChar ? 'O' : 'o',
          p: isFirstChar ? 'P' : 'p',
          a: isFirstChar ? 'A' : 'a',
          s: isFirstChar ? 'S' : 's',
          d: isFirstChar ? 'D' : 'd',
          f: isFirstChar ? 'F' : 'f',
          g: isFirstChar ? 'G' : 'g',
          h: isFirstChar ? 'H' : 'h',
          j: isFirstChar ? 'J' : 'j',
          k: isFirstChar ? 'K' : 'k',
          l: isFirstChar ? 'L' : 'l',
          z: isFirstChar ? 'Z' : 'z',
          x: isFirstChar ? 'X' : 'x',
          c: isFirstChar ? 'C' : 'c',
          v: isFirstChar ? 'V' : 'v',
          b: isFirstChar ? 'B' : 'b',
          n: isFirstChar ? 'N' : 'n',
          m: isFirstChar ? 'M' : 'm',
        }}
        theme="hg-theme-default"
        buttonTheme={[
          {
            class: 'hg-button',
            buttons:
              'q w e r t y u i o p a s d f g h j k l z x c v b n m {backspace}',
          },
        ]}
      />
    </StyledKeyboardContainer>
  );
};

export default Keyboard;
