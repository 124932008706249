import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { isNil } from 'ramda';

import QnSideBar from 'components/Assignment/AssignmentView/QnSideBar';
import {
  Canvas,
  ErrorModal,
  Spinner,
  SubmitModal,
  PopupModal,
  QuestionFeedbackLink,
  VideoPlayerModal,
} from 'components/Shared';
import QuestionContent from 'components/Question';
import QnText from 'components/Assignment/AssignmentView/QnText';
import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';

import NextIcon from 'assets/img/assignment/back-icon.svg';
import PrevIcon from 'assets/img/assignment/back-icon-blue.svg';
import VideoButtonImg from 'assets/img/kooquiz/kooQuizQn/video-button.png';

import {
  setKooQuizActiveQuestion,
  saveKooQuizAnswer,
  kooQuizCheckAnswer,
  finishKooQuiz,
  reset,
  openKooQuiz,
} from 'store/kooQuiz/kooQuizSlice';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import { clearVoiceoverPlaying } from 'store/voiceover/voiceoverSlice';
import { setLastSavedTime } from 'store/timer/timerSlice';
import { populateBarModel, clearAll } from 'store/assignment/barModelSlice';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';

import { submissionTypeIDs, questionTypeIDs } from 'constants/index';
import {
  kooQuizPaperTypeInternal,
  KOOQUIZ_SUBJECT_ID,
} from 'constants/kooQuiz';

import answersEqualsChecker from 'helpers/validation/answersEqualsChecker';
import newSaveAnswerHandler from 'helpers/saveAnswerHandler';
import newfinishAssignmentHandler from 'helpers/finishAnswerHandler';
import parseWorkingsBarModel from 'helpers/parseWorkingsBarModel';
import { getWorkings } from 'helpers/getWorkings';
import useQuery from 'helpers/useQuery';
import { sendEventTracking } from 'helpers/UserEventTracking';
import { assignmentTypeStyles } from './constants';
import { Header } from '../components';

import { Container, Main, QnButton } from './KooQuizQnView.styles';

const KooQuizQnView = () => {
  const { t } = useTranslation(['assignmentQnView']);
  const store = useStore();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const paperId = query.get('id');
  const hasPrint = query.get('print')?.toLowerCase() !== 'false';

  const { data: barModelData } = useSelector((state) => state.barModel);
  const {
    kooQuizType,
    paperById,
    kooQuizSubmissionID,
    kooQuizQuestions,
    kooQuizQuestionAnswers,
    kooQuizSubmissions,
    kooQuizSavedAnswers,
    kooQuizLocalSavedAnswers,
    kooQuizActiveQuestion,
    isKooQuizOpening,
    isLoading,
    kooQuizSaveError,
    error,
    kooQuizSavedWorkings,
    kooQuizCheckAnswerError,
    isKooQuizSaving,
    isKooQuizChecking,
  } = useSelector((state) => state.kooQuiz);
  const submissionType = submissionTypeIDs.Homework;

  const assignmentType = kooQuizType;
  const setActive = setKooQuizActiveQuestion;
  const activeQn = kooQuizActiveQuestion;
  const submissionId = kooQuizSubmissionID;
  const allQs = kooQuizQuestions;
  const allQsAns = kooQuizQuestionAnswers;
  const allSubmission = kooQuizSubmissions;
  const savedAns = kooQuizSavedAnswers;
  const localSavAns = kooQuizLocalSavedAnswers;
  const localSavWorkings = kooQuizSavedWorkings;
  const opening = isKooQuizOpening;
  const loading = isLoading;
  const isSaveErr = kooQuizSaveError;
  const isErr = error;
  const saving = isKooQuizSaving;
  const checking = isKooQuizChecking;

  // Loading state for Open Assignment & Get Assignment Qns API
  const isPending = opening || loading;
  // Required params for saving question submission
  const { userID } = useSelector((state) => state.login);
  // COMPONENT STATES
  // Finish modal state
  const [finishModal, setFinishModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoId, setVideoId] = useState('');

  // EVENT HANDLERS
  const dispatchSaveAction = (params, needCheckAnswersEqual = false) => {
    if (
      params &&
      needCheckAnswersEqual &&
      answersEqualsChecker(params, allSubmission)
    ) {
      return;
    }
    dispatch(saveKooQuizAnswer(params));
  };
  const dispatchCheckAnswerAction = (params) => {
    dispatch(kooQuizCheckAnswer(params));
  };

  const dispatchFinishAssignmentAction = (params) => {
    dispatch(finishKooQuiz(params));
  };

  // USEEFFECTS
  // Populate bar model data based on current active question
  useEffect(() => {
    if (
      allSubmission[activeQn - 1]?.SubmissionModel !== null &&
      parseWorkingsBarModel(
        allSubmission[activeQn - 1]?.SubmissionModel.Solution
      ).barModel !== null
    ) {
      dispatch(
        populateBarModel(
          parseWorkingsBarModel(
            allSubmission[activeQn - 1]?.SubmissionModel.Solution
          ).barModel
        )
      );
    }
  }, [activeQn, allSubmission, dispatch]);

  // Cleanup when page is unmounted
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    // EVENT TRACKING: onpage load
    sendEventTracking('kooquiz', 'question_view', {
      sub: KOOQUIZ_SUBJECT_ID[kooQuizType],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAnswerBE = (props) => {
    const now = Date.now();
    newSaveAnswerHandler({
      assignmentSubmissionID: submissionId,
      assignmentType: 'homework',
      questionType: questionTypeIDs[allQs[activeQn - 1].QuestionType],
      submissionType,
      questionAnswer: allQsAns[activeQn - 1],
      saveAnswerAction: (params) =>
        dispatchSaveAction(params, props?.needCheckAnswersEqual),
      checkAnswerAction: dispatchCheckAnswerAction,
      activeQnNo: activeQn,
      currentQn: allQs[activeQn - 1],
      currentLocalSavedAnswer: localSavAns[activeQn - 1],
      currentSubmission: allSubmission[activeQn - 1].SubmissionModel,
      timeElapsed: now - store.getState().timer.lastSavedTime,
      userID,
      workings: getWorkings(
        allSubmission[activeQn - 1],
        localSavWorkings[activeQn - 1]
      ),
      barModel: barModelData.objects.length > 0 ? barModelData : null,
    });
    if (props?.needCheckAnswersEqual) {
      dispatch(setClearDraw(true));
    }
  };
  const finishAssignmentModal = async () => {
    const now = Date.now();
    newfinishAssignmentHandler({
      assignmentSubmissionID: submissionId,
      questionType: questionTypeIDs[allQs[activeQn - 1].QuestionType],
      submissionType,
      questionAnswer: allQsAns[activeQn - 1],
      activeQnNo: activeQn,
      currentQn: allQs[activeQn - 1],
      currentLocalSavedAnswer: localSavAns[activeQn - 1],
      currentSubmission: allSubmission[activeQn - 1].SubmissionModel,
      userID,
      timeElapsed: now - store.getState().timer.lastSavedTime,
      finishAssignmentAction: dispatchFinishAssignmentAction,
      workings: getWorkings(
        allSubmission[activeQn - 1],
        localSavWorkings[activeQn - 1]
      ),
      barModel: barModelData.objects.length > 0 ? barModelData : null,
    });
    history.push(`/kooQuiz/finish?print=${hasPrint}&id=${paperId}`);
  };

  const openModal = () => {
    setFinishModal(true);
    dispatch(clearVoiceoverPlaying());
  };

  const closeModal = () => {
    setFinishModal(false);
  };

  const reloadHandler = () => {
    dispatch(openKooQuiz(paperId));
  };

  const playVideoHandler = (id) => {
    return () => {
      setVideoId(id);
      setShowVideoModal(true);
    };
  };
  return (
    <Container>
      <PopupModal show={finishModal} backdrop="static">
        <SubmitModal
          type={t('assignmentQnView:finishModal.important', 'Important!')}
          header={t(
            'assignmentQnView:finishModal.confirmSubmission',
            'Confirm Submission'
          )}
          title={t(
            'assignmentQnView:finishModal.confirmSubmit',
            'Are you sure you want to submit?'
          )}
          desc={t(
            'assignmentQnView:finishModal.submitDesc',
            'Please make sure you do not leave any question(s) unanswered.'
          )}
          noHandle={closeModal}
          yesHandle={finishAssignmentModal}
        />
      </PopupModal>
      <Canvas
        feature="kooQuiz"
        bgColor={assignmentTypeStyles[assignmentType].helperBg}
      >
        <>
          <Header
            isLoading={isPending}
            assignmentTitle={paperById && paperById.Name}
            assignmentDeadline={paperById && paperById.Deadline}
            userID={userID}
            currentQuestion={allQs[activeQn - 1]}
            currentAnswerKeys={allQsAns[activeQn - 1]}
            currentLocalSavedAnswer={localSavAns[activeQn - 1]}
            currentSavedAnswer={savedAns[activeQn - 1]}
            openModal={openModal}
            saveHandler={saveAnswerBE}
            isErr={isErr}
            showPrint={hasPrint}
            metaData={{
              subjectid: KOOQUIZ_SUBJECT_ID[kooQuizType],
              papertypeid: kooQuizPaperTypeInternal[paperById?.Type],
            }}
            isSaving={saving}
            saveErr={isSaveErr}
            isChecking={checking}
            activeQuestion={activeQn}
          />
          {!isPending && isErr === null && (
            <QnSideBar
              assignmentType={kooQuizType}
              localSavedAnswers={localSavAns}
              questions={allQs}
              activeQuestion={activeQn}
              answersEqualsChecker={answersEqualsChecker}
            />
          )}
          <Main assignmentType={kooQuizType}>
            <VideoPlayerModal
              isOpen={showVideoModal}
              youtube={{
                autoplay: 1,
                mute: 0,
                rel: 0,
              }}
              videoId={videoId}
              onClose={() => setShowVideoModal(false)}
            />
            <div className="note-book-wrap">
              <div className="note-book">
                <div className="notebook-top">
                  {!isPending && isErr === null && (
                    <>
                      <div className="text">
                        <QnText />
                        <p className="qn-number">{activeQn}</p>
                        <p className="qn-id">
                          {t('assignmentQnView:main.qnId', 'Qn ID')}{' '}
                          {allQs[activeQn - 1]?.Id}
                        </p>
                        {!isNil(allQs[activeQn - 1]?.QuestionVideoCode) &&
                          allQs[activeQn - 1]?.QuestionVideoCode.length > 0 && (
                            <img
                              src={VideoButtonImg}
                              alt="Video"
                              className="video-button"
                              onClick={playVideoHandler(
                                allQs[activeQn - 1]?.QuestionVideoCode
                              )}
                            />
                          )}
                      </div>
                      <div className="action-btn">
                        {activeQn > 1 && (
                          <QnButton
                            type="prev"
                            onClick={() => {
                              const now = Date.now();
                              saveAnswerBE({ needCheckAnswersEqual: true });
                              dispatch(setLastSavedTime(now));
                              dispatch(hideAllTools());
                              dispatch(clearAll());
                              clearValues();
                              dispatch(setActive(activeQn - 1));
                            }}
                            disabled={saving || checking[activeQn - 1]}
                          >
                            <img src={PrevIcon} alt="Previous Qn" />
                            {t('assignmentQnView:main.previous', 'Previous Qn')}
                          </QnButton>
                        )}
                        <QnButton
                          type="next"
                          disabled={saving || checking[activeQn - 1]}
                          onClick={() => {
                            if (activeQn < allQs.length) {
                              const now = Date.now();
                              saveAnswerBE({ needCheckAnswersEqual: true });
                              dispatch(setLastSavedTime(now));
                              dispatch(hideAllTools());
                              dispatch(clearAll());
                              clearValues();
                              dispatch(setActive(activeQn + 1));
                              dispatch(setClearDraw(true));
                            } else {
                              openModal();
                            }
                          }}
                        >
                          {activeQn === allQs.length ? (
                            t('assignmentQnView:header.submit', 'Submit')
                          ) : (
                            <>
                              {t('assignmentQnView:main.next', 'Next Question')}{' '}
                              <img src={NextIcon} alt="Next Question" />
                            </>
                          )}
                        </QnButton>
                      </div>
                    </>
                  )}
                </div>
                <div className="notebook-middle">
                  {isPending && (
                    <Spinner
                      color="#00A49A"
                      top="35%"
                      position="absolute"
                      left="52%"
                    />
                  )}
                  {!isPending && isErr === null && (
                    <QuestionContent
                      page={assignmentType}
                      submissionType={submissionType}
                      questionType={
                        questionTypeIDs[allQs[activeQn - 1]?.QuestionType]
                      }
                      qnID={allQs[activeQn - 1]?.Id}
                      xml={
                        allSubmission[activeQn - 1]?.SubmissionModel !== null
                          ? allSubmission[activeQn - 1]?.SubmissionModel.Payload
                          : allQs[activeQn - 1]?.Payload
                      }
                      showCheckAnswer
                      showSolution={false}
                      showSubmissions={
                        allSubmission[activeQn - 1]?.SubmissionModel !== null
                      }
                      assignmentType={assignmentType}
                    />
                  )}
                </div>
                <div className="notebook-bottom">
                  <QuestionFeedbackLink
                    qnID={allQs[activeQn - 1]?.Id}
                    skillID={allQs[activeQn - 1]?.SkillId}
                    margin="1rem 0 0 0"
                  />
                </div>
              </div>
            </div>
          </Main>
        </>
      </Canvas>

      {((!isPending && !isNil(isErr)) ||
        !isNil(kooQuizCheckAnswerError) ||
        !isNil(isSaveErr)) && (
        <ErrorModal
          reloadAction={isErr && reloadHandler}
          errorMessage={isErr || kooQuizCheckAnswerError || isSaveErr}
        />
      )}
    </Container>
  );
};

export default KooQuizQnView;
