import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'ramda';

import {
  fetchWodPreferences,
  fetchWodGameStatus,
  postWodLevel,
  postWodTutorial,
  fetchWordsList,
  postGameHint,
  postGameSubmit,
  fetchGameResult,
  fetchWordDefinition,
  fetchRetryWordsList,
  fetchTranslationLanguages,
  fetchWordTranslationByLangId,
  fetchWordCreationStatus,
  postWodCreation,
} from 'services/wod';
import { fetchWrapper } from 'services/login';

import { handleReducer } from 'helpers/storeReducer';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import { compareValues } from 'helpers/compareValue';

import { WOD_GAME_TYPE, WOD_STATUS } from 'pages/WordsOfTheDay/constant';

export const getWodPreferences = createAsyncThunk(
  'wod/getWodPreferences',
  async () => {
    try {
      const result = await fetchWrapper(fetchWodPreferences);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Get user wod preferences failed');
    }
  }
);

export const updateWodLevel = createAsyncThunk(
  'wod/updateWodLevel',
  async (params) => {
    try {
      const result = await fetchWrapper(postWodLevel, params);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Update user wod level failed');
    }
  }
);

export const getWodGameStatus = createAsyncThunk(
  'wod/getWodGameStatus',
  async () => {
    try {
      const result = await fetchWrapper(fetchWodGameStatus);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Get user wod game status failed');
    }
  }
);

export const updateWodTutorial = createAsyncThunk(
  'wod/updateWodTutorial',
  async (params) => {
    try {
      const result = await fetchWrapper(postWodTutorial, params);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Update user wod tutorial failed');
    }
  }
);

export const getWordsList = createAsyncThunk(
  'wod/getWordsList',
  async (params) => {
    try {
      const listRes = await fetchWrapper(fetchWordsList, params.gameType);
      const resultRes =
        params.status === WOD_STATUS.IN_PROGRESS
          ? await fetchWrapper(fetchGameResult, {
              gameType: params.gameType,
              inquiryDate: params.inquiryDate,
            })
          : null;
      return {
        wordsList: handleResponseV2(listRes),
        resultList: !isNil(resultRes) ? handleResponseV2(resultRes) : null,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Get game words list failed');
    }
  }
);

export const updateHint = createAsyncThunk(
  'wod/updateHint',
  async (gameWordId) => {
    try {
      const result = await fetchWrapper(postGameHint, gameWordId);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Update hint failed');
    }
  }
);

export const submitGameAnswer = createAsyncThunk(
  'wod/submitGameAnswer',
  async (body) => {
    try {
      const result = await fetchWrapper(postGameSubmit, body);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Submit game answer failed');
    }
  }
);

export const getGameResult = createAsyncThunk(
  'wod/getGameResult',
  async (params) => {
    try {
      const result = await fetchWrapper(fetchGameResult, params);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Get game result failed');
    }
  }
);

export const getWordDefinition = createAsyncThunk(
  'wod/getWordDefinition',
  async (wordEntryId) => {
    try {
      const result = await fetchWrapper(fetchWordDefinition, wordEntryId);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Get word definition failed');
    }
  }
);

export const getRetryWordsList = createAsyncThunk(
  'wod/getRetryWordsList',
  async ({ gameType }) => {
    try {
      const result = await fetchWrapper(fetchRetryWordsList, gameType);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Get retry words list failed');
    }
  }
);

export const getTranslationLanguages = createAsyncThunk(
  'wod/getTranslationLanguages',
  async () => {
    try {
      const result = await fetchWrapper(fetchTranslationLanguages);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get translation languages list failed'
      );
    }
  }
);

export const getWordTranslation = createAsyncThunk(
  'wod/getWordTranslation',
  async (params) => {
    try {
      const result = await fetchWrapper(fetchWordTranslationByLangId, params);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Get word translation failed');
    }
  }
);

export const createWords = createAsyncThunk(
  'wod/createWords',
  async (params) => {
    try {
      const result = await fetchWrapper(postWodCreation, params);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Word creation failed');
    }
  }
);

export const getWordCreationStatus = createAsyncThunk(
  'wod/getWordCreationStatus',
  async () => {
    try {
      const result = await fetchWrapper(fetchWordCreationStatus);
      return handleResponseV2(result);
    } catch (error) {
      throw new Error(error?.message ?? 'Get word creation status failed');
    }
  }
);

const initialState = {
  wodPreferences: {
    data: null,
    isLoading: false,
    isError: null,
  },
  wodLevelUpdate: {
    data: null,
    isLoading: false,
    isError: null,
  },
  wodGameStatus: {
    data: null,
    isLoading: false,
    isError: null,
  },
  wodTutorialUpdate: {
    data: null,
    isLoading: false,
    isError: null,
  },
  wordsList: {
    koocardsList: [],
    spellingBitsList: [],
    isLoading: false,
    isError: null,
  },
  hint: {
    isLoading: false,
    isError: null,
    isSuccess: false,
  },
  submitResult: {
    isLoading: false,
    isError: null,
    data: null,
  },
  gameResult: {
    isLoading: false,
    isError: null,
    data: null,
    koocardsResult: null,
    spellingBitsResult: null,
  },
  wordDefinition: {
    isLoading: false,
    isError: null,
    data: null,
  },
  languagesList: {
    isLoading: false,
    isError: null,
    data: [],
  },
  wordTranslation: {
    isLoading: false,
    isError: null,
    data: null,
  },
  wodCreation: {
    status: null,
    isSuccess: false,
    isLoading: false,
    isError: null,
  },
};

const wodSlice = createSlice({
  name: 'wod',
  initialState,
  reducers: {
    resetWodPreferences: (state) => {
      state.wodPreferences.data = null;
    },
  },
  extraReducers: (builder) => {
    handleReducer({
      initialState,
      fetchAction: getWodPreferences,
      stateTitle: 'wodPreferences',
      builder,
      customPending: (state) => {
        state.wodPreferences.isLoading = true;
        state.wodPreferences.data = null;
        state.wodPreferences.isError = null;
      },
    });
    handleReducer({
      initialState,
      fetchAction: updateWodLevel,
      stateTitle: 'wodLevelUpdate',
      builder,
      customPending: (state) => {
        state.wodLevelUpdate.isLoading = true;
        state.wodLevelUpdate.data = null;
        state.wodLevelUpdate.isError = null;
      },
    });
    handleReducer({
      initialState,
      fetchAction: getWodGameStatus,
      stateTitle: 'wodGameStatus',
      builder,
      customPending: (state) => {
        state.wodGameStatus.isLoading = true;
        state.wodGameStatus.data = null;
        state.wodGameStatus.isError = null;
      },
    });
    handleReducer({
      initialState,
      fetchAction: updateWodTutorial,
      stateTitle: 'wodTutorialUpdate',
      builder,
      customPending: (state) => {
        state.wodTutorialUpdate.isLoading = true;
        state.wodTutorialUpdate.data = null;
        state.wodTutorialUpdate.isError = null;
      },
    });
    handleReducer({
      initialState,
      fetchAction: getWordsList,
      stateTitle: 'wordsList',
      builder,
      customPending: (state, action) => {
        state.wordsList.isLoading = true;
        const listType =
          action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS
            ? 'koocardsList'
            : 'spellingBitsList';
        state.wordsList[listType] = [];
        state.wordsList.isError = null;
      },
      customFulfilled: (state, action) => {
        const parsedData = action.payload.wordsList?.reduce((result, list) => {
          const resultItem = !isNil(action.payload.resultList)
            ? action.payload.resultList.gameWords.find(
                (item) => item.gameWordId === list.gameWordId
              )
            : null;
          const combined = {
            ...list,
            ...resultItem,
          };
          result.push(combined);
          return result;
        }, []);

        state.wordsList.isLoading = false;
        const listType =
          action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS
            ? 'koocardsList'
            : 'spellingBitsList';
        state.wordsList[listType] = parsedData?.sort(
          compareValues('wordOrder')
        );
        state.wordsList.isError = null;
      },
      customRejected: (state, action) => {
        state.wordsList.isLoading = false;
        const listType =
          action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS
            ? 'koocardsList'
            : 'spellingBitsList';
        state.wordsList[listType] = [];
        state.wordsList.isError = action.error.message;
      },
    });
    handleReducer({
      initialState,
      fetchAction: updateHint,
      stateTitle: 'hint',
      builder,
      customFulfilled: (state) => {
        state.hint.isLoading = false;
        state.hint.isSuccess = true;
      },
    });
    handleReducer({
      initialState,
      fetchAction: submitGameAnswer,
      stateTitle: 'submitResult',
      builder,
      customPending: (state) => {
        state.submitResult.isLoading = true;
        state.submitResult.data = null;
        state.submitResult.isError = null;
      },
    });
    handleReducer({
      initialState,
      fetchAction: getGameResult,
      stateTitle: 'gameResult',
      builder,
      customPending: (state, action) => {
        state.gameResult.isLoading = true;
        if (action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS) {
          state.gameResult.koocardsResult = null;
        } else {
          state.gameResult.spellingBitsResult = null;
        }
        state.gameResult.isError = null;
      },
      customFulfilled: (state, action) => {
        state.gameResult.isLoading = false;
        if (action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS) {
          state.gameResult.koocardsResult = {
            ...action.payload,
            gameWords: action.payload.gameWords.sort(
              compareValues('wordOrder')
            ),
          };
        } else {
          state.gameResult.spellingBitsResult = {
            ...action.payload,
            gameWords: action.payload.gameWords.sort(
              compareValues('wordOrder')
            ),
          };
        }
        state.gameResult.isError = null;
      },
    });
    handleReducer({
      initialState,
      fetchAction: getWordDefinition,
      stateTitle: 'wordDefinition',
      builder,
      customPending: (state) => {
        state.wordDefinition.isLoading = true;
        state.wordDefinition.data = null;
        state.wordDefinition.isError = null;
      },
    });
    handleReducer({
      initialState,
      fetchAction: getRetryWordsList,
      stateTitle: 'wordsList',
      builder,
      customPending: (state, action) => {
        const listType =
          action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS
            ? 'koocardsList'
            : 'spellingBitsList';

        state.wordsList.isLoading = true;
        state.wordsList[listType] = [];
        state.wordsList.isError = null;
      },
      customFulfilled: (state, action) => {
        const listType =
          action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS
            ? 'koocardsList'
            : 'spellingBitsList';
        const resultType =
          action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS
            ? 'koocardsResult'
            : 'spellingBitsResult';

        state.wordsList.isLoading = false;
        state.wordsList[listType] = action.payload?.sort(
          compareValues('wordOrder')
        );
        state.gameResult[resultType] = null;
        state.wordsList.isError = null;
      },
      customRejected: (state, action) => {
        const listType =
          action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS
            ? 'koocardsList'
            : 'spellingBitsList';
        const resultType =
          action.meta.arg.gameType === WOD_GAME_TYPE.KOOCARDS
            ? 'koocardsResult'
            : 'spellingBitsResult';

        state.wordsList.isLoading = false;
        state.wordsList[listType] = [];
        state.gameResult[resultType] = null;
        state.wordsList.isError = action.error.message;
      },
    });
    handleReducer({
      initialState,
      fetchAction: getTranslationLanguages,
      stateTitle: 'languagesList',
      builder,
      customPending: (state) => {
        state.languagesList.isLoading = true;
        state.languagesList.data = [];
        state.languagesList.isError = null;
      },
      customRejected: (state, action) => {
        state.languagesList.isLoading = false;
        state.languagesList.data = [];
        state.languagesList.isError = action.error.message;
      },
    });
    handleReducer({
      initialState,
      fetchAction: getWordTranslation,
      stateTitle: 'wordTranslation',
      builder,
      customPending: (state) => {
        state.wordTranslation.isLoading = true;
        state.wordTranslation.data = null;
        state.wordTranslation.isError = null;
      },
    });
    handleReducer({
      initialState,
      fetchAction: createWords,
      stateTitle: 'wodCreation',
      builder,
      customPending: (state) => {
        state.wodCreation.isLoading = true;
        state.wodCreation.isSuccess = false;
        state.wodCreation.isError = null;
      },
      customFulfilled: (state) => {
        state.wodCreation.isLoading = false;
        state.wodCreation.isSuccess = true;
        state.wodCreation.isError = null;
      },
      customRejected: (state, action) => {
        state.wodCreation.isLoading = false;
        state.wodCreation.isSuccess = false;
        state.wodCreation.isError = action.error.message;
      },
    });
    handleReducer({
      initialState,
      fetchAction: getWordCreationStatus,
      stateTitle: 'wodCreation',
      builder,
      customPending: (state) => {
        state.wodCreation.isLoading = true;
        state.wodCreation.status = null;
        state.wodCreation.isError = null;
      },
      customFulfilled: (state, action) => {
        state.wodCreation.isLoading = false;
        state.wodCreation.status = action.payload.processStatusId;
        state.wodCreation.isError = null;
      },
      customRejected: (state, action) => {
        state.wodCreation.isLoading = false;
        state.wodCreation.status = null;
        state.wodCreation.isError = action.error.message;
      },
    });
  },
});

const { actions, reducer } = wodSlice;
export const { resetWodPreferences } = actions;
export default reducer;
